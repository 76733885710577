
/*------------------------------------*\
    #Services
\*------------------------------------*/
.service .service-img {
	margin-bottom: 30px;
}

.service .service-desc h4 {
	margin-bottom: 20px;
	color: @color-dark6;
	font-size: 17px;
	font-weight: 700;
	line-height: 25px;
	text-transform: uppercase;
}

.service-3 h4,
.service-4 .service-content h4,
.service-5 h4,
.service-6 h4 {
	position: relative;
	padding-bottom: 10px;
}

.service .service-desc p {
	font-size: 14px;
	font-weight: 400;
	line-height: 23px;
}

.service .service-desc a.read-more {
font-family:@font-heading;
	font-size: 12px;
	font-weight: bold;
	text-transform: uppercase;
color:@color-heading;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.service .service-desc a.read-more i {
	margin-right: 8px;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.service .service-desc a.read-more:hover {
color:@color-theme
}

/* service #1 */
.service-1 .service-content > img {
	position: absolute;
	left: 0;
	padding-top: 30px;
	padding-left: 20px;
}

.service-1 .service-desc {
	padding-left: 70px;
}

.service-block .service-desc a.read-more span {
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.service-block:hover .service-desc a.read-more span {
	opacity: 1;
	visibility: visible
}

/* service #2 */
.service-2 .nav-tabs {
	border-bottom: none;
	margin-bottom: 50px;
}

.service-2 .nav-tabs li {
	text-align: center;
	padding-right: 0;
	padding-left: 0
}

.service-2 .nav-tabs li a {
font-family:@font-heading;
	font-size: 17px;
	font-weight: bold;
	text-transform: uppercase;
color:@color-heading;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
	border: none;
	padding: 0
}

.service-2 .nav-tabs li a:hover {
	border-color: transparent
}

.service-2 .nav-tabs li.active a,
.service-2 .nav-tabs li.active a:hover,
.service-2 .nav-tabs li.active a:focus,
.service-2 .nav-tabs li a:hover,
.service-2 .nav-tabs li a:focus {
	background-color: transparent;
	border: none;
color:@color-theme !important;
	background-color: transparent;
}

.service-2 .nav-tabs li .service-icon {
	margin-bottom: 20px;
	height: 50px;
	overflow: hidden
}

.service-2 .nav-tabs li .service-icon img {
	display: block;
	margin-right: auto;
	margin-left: auto;
	text-align: center
}

.service-2 .nav-tabs li .service-icon img:first-of-type {
	margin-top: -48px;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.service-2 .nav-tabs li .service-icon img:last-of-type {
	position: relative;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.service-2 .nav-tabs li.active img:first-of-type,
.service-2 .nav-tabs li a:hover img:first-of-type {
	margin-top: 0;
}

.service-2 .nav-tabs li.active img:last-of-type,
.service-2 .nav-tabs li a:hover img:last-of-type {
	margin-top: 48px;
}

.service-2 .col-img { height:716px;}
.service-2 .tab-pane h3 {
	font-size: 30px;
	font-weight: 700;
	line-height: 25px;
	text-transform: uppercase;
	color:@color-white;
	margin-bottom: 50px;
	letter-spacing: 1px;
}

.service-2 .tab-pane p {
	font-size: 16px;
	font-weight: 400;
color:@color-white;
margin-bottom:45px;
}

.service-2 .tab-pane .tab-count {
	position: absolute;
	right: 0;
	font-size: 67px;
font-family:@font-heading;
	font-weight: bold;
color:@color-theme
}

.service-2 .col-content {
	padding: 100px;
}

/* service #3 */
.service-3 .service-block h4:before {
	content: "";
	height: 2px;
	width: 20px;
	position: absolute;
	bottom: 0;
	display: inline-block;
background-color:@color-theme;
}

.service-3 .service-block:hover h4,
.service-4 .service-block:hover h4,
.service-5 .service-block:hover h4 {
color:@color-theme
}

.service-3 .service-block .service-img {
	width: 48px;
	height: 48px;
	overflow: hidden;
	margin: auto;
}

.service-3 .service-block .service-img img {
	display: block;
}
.service-content {
	text-align: center;
}
.service-3 .service-block .service-img img:first-of-type {
	margin-left: -48px;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.service-3 .service-block .service-img img:last-of-type {
	position: relative;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
	margin-top: -48px;
}

.service-3 .service-block:hover .service-img img:first-of-type {
	margin-left: 0;
}

.service-3 .service-block:hover .service-img img:last-of-type {
	margin-left: 48px;
}

/* service #4 */

.service-4 .service-content {
	margin-bottom: 60px;
}

.service-4 .service-content2 {
	padding-left: 100px;
}

.service-4 .service-content2 h4 {
	padding-bottom: 0;
	margin-bottom: 10px;
}

.service-4 .service-content2 .service-desc {
	padding-left: 0
}

.service-4 .service-block:hover h4:before,
.service-5 .service-block:hover h4:before {
background-color:@color-theme;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.service-4 .service-icon {
	width: 70px;
	height: 70px;
	line-height: 70px;
	text-align: center;
background-color:@color-dark6;
	position: relative;
	margin-left: 30px;
	margin-top: -70px;
	margin-bottom: 20px;
	overflow: hidden;
	padding-top: 13px;
	padding-bottom: 13px;
}

.service-4 .service-block .service-icon img {
	display: block;
	margin-right: auto;
	margin-left: auto;
	text-align: center
}

.service-4 .service-block .service-icon img:first-of-type {
	margin-left: -70px;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.service-4 .service-block .service-icon img:last-of-type {
	position: relative;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
	margin-top: -48px;
}

.service-4 .service-block:hover .service-icon img:first-of-type {
	margin-left: 13px;
}

.service-4 .service-block:hover .service-icon img:last-of-type {
	margin-left: 70px;
}

.service-4 .service-block:hover .service-icon {
background-color:@color-theme;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

/* service #5 */
.service-4 .service-content h4:before,
.service-5 h4:before,
.service-6 h4:before {
	content: "";
	height: 2px;
	width: 20px;
	position: absolute;
	bottom: 0;
	display: inline-block;
background-color:@color-heading;
}

.service-4 .service-desc,
.service-5 .service-desc {
	padding-left: 30px;
}

/* service #6 */
.service-6 .col-img {
	height: 761px;
}

.service-6 .heading {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
}

.service-6 .heading .heading-bg {
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.service-6 .heading h2,
.service-6 .heading p {
color:@color-white
}

.service-6 .service-icon {
	width: 70px;
	height: 70px;
	line-height: 70px;
	text-align: center;
background-color:@color-dark6;
	position: relative;
	margin-bottom: 20px;
}

.service-6 .col-content {
	padding: 0;
}

.service-6 .service-block {
	padding: 50px 20px 50px 40px;
	border-left: 1px solid @color-gray9
}

.service-6 .service-block:nth-child(3),
.service-6 .service-block:nth-child(4) {
	border-top: 1px solid @color-gray9
}

.service-6 p {
	margin-bottom: 10px;
}

.service-6 {
	border-top: 1px solid @color-gray9;
	border-bottom: 1px solid @color-gray9
}

.service-6 .service-block:hover {
background-color:@color-theme
}

.service-6 .service-block:hover h4,
.service-6 .service-block:hover p {
color:@color-white
}

.service-6 .service-block:hover h4:before {
background-color:@color-white
}

.service-6 .service-block:hover .service-icon {
background-color:@color-theme
}

.service-6 .service-desc a.read-more:hover { color:@color-white;}

/* service #7 */
.service-7 .service-block h4 {
	margin-bottom: 10px;
}

/* service #8 */

.service-8 .col-content {
	background-color: rgba(255,197,39,.9);
	margin-top: 100px;
	margin-bottom: 100px;
	padding: 100px;
}

.service-8 .tab-content > .tab-pane {
	height: 720px;
}

.service-8 .tab-pane h3 .service .service-img2 {
	position: relative
}

.service .service-img2 >img {
	position: absolute;
	left: 0;
	top: 0
}


.service2 .service-block {
	margin-bottom: 50px;
}
/* Single Services */
.single-service .service-img {
	margin-bottom: 70px;
}

.single-service .service-title h3 {
	position: relative;
	padding-bottom: 10px;
	font-size: 20px;
	font-weight: bold;
	text-transform: uppercase
}

.single-service .service-title h3:before {
	content: "";
	height: 3px;
	width: 20px;
	position: absolute;
	bottom: 0;
	display: inline-block;
 background-color:@color-theme;
}

.single-service .feature h4 { font-size:16px; line-height:1;}
.single-service .feature p { margin-bottom:50px;}

/* Custom, iPhone Retina */ 
@media only screen and (min-width : 320px) and (max-width : 479px) {

.service-1 .service-img img,
.service-4 .service-img img,
.service-5 .service-img img,
.single-service .service-img img {
	width: 100%;
	margin-right: auto;
	margin-left: auto
}

.service-1 .service-block,
.service-3 .service-block,
.service-5 .service-block,
.service-7 .service-block {
	margin-bottom: 30px;
}

.service-1 .service-block:last-of-type,
.service-3 .service-block:last-of-type,
.service-5 .service-block:last-of-type,
.service-7 .service-block:last-of-type {
	margin-bottom: 0
}

.service-2 .nav-tabs li {
	float: none;
	margin-bottom: 10px;
}

.service-2 .tab-pane h3 {
	font-size: 30px;
}

.service-2 .col-content {
	padding-right: 15px;
	padding-top: 70px;
	padding-left: 15px;
	text-align: center;
}

.service .service-img2 > img {
	position: relative;
	display: block;
	left: 0;
	top: 0;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 10px;
}

.service-4 .service-content2 {
	padding-left: 0;
	margin-right: auto;
	margin-left: auto;
	text-align: center
}

.service-6 .service-block:nth-child(3),
.service-6 .service-block:nth-child(4) {
	border-top: none;
}

.service-6 .service-block {
	padding-bottom: 0
}

.service-6 .service-block:last-of-type {
	padding-bottom: 50px;
}

.service-6 .col-img,
.service-2 .col-img {
	height: 461px;
}

.service-8 .tab-content > .tab-pane {
	height: auto;
}

.single-service .sidebar-services {
	margin-bottom: 50px;
}
}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px)and (max-width : 767px ) {

.single-service .service-img img {
	width: 100%;
}

.service-1 .service-block,
.service-3 .service-block,
.service-5 .service-block,
.service-7 .service-block {
	margin-bottom: 30px;
}

.service-2 .nav-tabs {
	padding-left: 30px;
	padding-right: 30px;

}

.service-2 .nav-tabs li {
	margin-right: 15px;
	margin-bottom: 15px;

    text-align: center;
    width: 100%;

}

.service-6 .col-img,
.service-2 .col-img {
	height: 561px;
}

.service-2 .col-content {
	padding-right: 15px;
	padding-top: 70px;
	padding-left: 15px;
	text-align: center;
}

.service .service-img2 > img {
	left: auto
}
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) and (max-width : 991px) {

.service-1 .service-img img,
.service-4 .service-img img,
.service-5 .service-img img {
	width: 100%;
	margin-right: auto;
	margin-left: auto
}

.service-2 .nav-tabs li {
	margin-right: 15px;
	margin-bottom: 15px;
}

.service-2 .col-content {
	padding: 50px 15px 50px 50px;
}

.service .service-img2 > img {
	left: auto
}

.service-6 .col-img {
	height: 461px;
}

.service-7 .service-block {
	margin-bottom: 30px;
}

.service-7 .service-block:nth-child(3),
.service-7 .service-block:nth-child(4) {
	margin-bottom: 0;
}

.service-8 .tab-content > .tab-pane {
	height: auto;
}

.single-service .service-img img {
	width: 100%;
}

.service2 .service-block:nth-child(3),
.service2 .service-block:nth-child(4) {
	margin-bottom: 50px;
}
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) and (max-width : 1200px) {

.service-1 .service-img img,
.service-4 .service-img img,
.service-5 .service-img img,
.service-7 .service-img img {
	width: 100%;
	margin-right: auto;
	margin-left: auto
}

.service-2 .col-content {
	padding-right: 90px;
	padding-top: 70px;
}

.service .service-img2 > img {
	left: auto
}

.service-6 .service-block {
	padding-top: 30px;
	padding-bottom: 30px;
}

.service-6 .col-img {
	height: 772px;
}

.service-8 .tab-content > .tab-pane {
	height: auto;
}

.single-service .service-img img {
	width: 100%;
}

.sidebar-services .download {
	padding: 24px 12px;
}

.sidebar-services .download .download-icon i {
	padding-left: 12px;
}
}
