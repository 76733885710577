
/*------------------------------------*\
    #TYPOGRAPHY
\*------------------------------------*/

/* Heading Text */
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
 color:@color-heading;
 font-family:@font-heading;
	font-weight: 700;
	margin: 0 0 20px 0;
	line-height: 1.5;
}

h1,
.h1 {
 font-size:@font-size-h1;
}

h2,
.h2 {
 font-size:@font-size-h2;
}

h3,
.h3 {
 font-size:@font-size-h3;
}

h4,
.h4 {
 font-size:@font-size-h4;
}

h5,
.h5 {
 font-size:@font-size-h5;
}

h6,
.h6 {
 font-size:@font-size-h6;
}

p {
	color: @color-dark6;
	font-size: 16px;
	font-weight: 400;
 margin-bottom:@baseline;
	line-height: 23px;
}

.lead {
	font-size: 16px;
line-height:@line-height-content;
}

/* Aligning Text */
.text-left {
	text-align: left !important;
}

.text-right {
	text-align: right !important;
}

.text-center {
	text-align: center !important;
}

.text-just {
	text-align: justify !important;
}

.align-top {
	vertical-align: top;
}

.align-bottom {
	vertical-align: bottom;
}

.align-middle {
	vertical-align: middle;
}

.align-baseline {
	vertical-align: baseline
}

/* Weight Text */
.bold {
	font-weight: bold;
}

.regular {
	font-weight: normal;
}

.italic {
	font-style: italic;
}

.break-word {
	word-wrap: break-word;
}

.no-wrap {
	white-space: nowrap;
}

/* Text Color */
.text-white {
 color:@color-white !important;
}

.text-black {
 color:@color-heading;
}

.text-capitalize {
	text-transform: capitalize
}

.text-uppercase {
	text-transform: uppercase
}

.font-heading {
font-family:@font-heading;
}

.font-body {
font-family:@font-body;
}

.font-18 {
font-size:@font-size-h5;
}

.font-16 {
	font-size: 16px;
}

.font-40 {
	font-size: 40px;
}

.font-secondary {
font-family:@font-secondary
}

/* quotes style */
blockquot,
.blockquote {
	font-size: 20px;
 font-family:@font-body;
	font-style: italic;
	line-height: 28px;
	font-weight:  400;
	padding: 30px 0 30px 30px;
}

.quote-author {
	display: block;
	font-size: 13px;
	font-style: normal;
	margin-top: 20px;
	line-height: 28px;
}

.blockquote-1 {
border-left:6px solid @color-dark;
}

.blockquote-3,
.blockquote-4 {
	padding-right: 30px;
}

.blockquote-2,
.blockquote-3,
.blockquote-4 {
	border-left: none;
}

.blockquote-2:before {
	content: "\f10d";
	font-family: @awesome-font;
	font-size: 16px;
	position: absolute;
	top: 0;
	left: 0;
	color:@color-dark;
	line-height: @line-height-content;
}

.blockquote-2:after {
	content: "\f10e";
	font-family: @awesome-font;
	font-size: 16px;
	color:@color-dark;
	position: absolute;
	right: 0;
	bottom: 0;
	line-height: @line-height-content;
}

.blockquote-3 {
	color:@color-theme;
	background-color:@color-gray;
	border-radius:@radius-5;
}

.blockquote-3 .quote-author {
	color:@color-body
}

.blockquote-4 {
	color:@color-white;
	background-color:@color-dark;
	border-radius:@radius-5;
}

/* Lists */
ul.list {
	padding: 0;
	font-size: 13px;
	list-style-type: none;
}

ul.list li {
	font-size: 13px;
	font-weight:  400;
	line-height: 30px;
}

ul.icon-list li i {
	font-size: 15px;
	font-weight: 400;
	line-height: 30px;
	margin-right: 10px;
	color:@color-heading;
}

ul.number-list li span  {
	font-size: 13px;
	font-weight: 700;
	line-height: 30px;
	margin-right: 15px;
	color:@color-heading;
}

ul.dot-list li i {
	font-size: 7px;
	font-weight: 400;
	line-height: 30px;
	margin-right: 19px;
	color:@color-heading;
}
