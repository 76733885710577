/*------------------------------------*\
    #DEFAULT COLORS
\*------------------------------------*/
.navbar .logo img {

    height: 74px;
    padding: 10px;
    padding-top: 0;
    margin-top: -5px;

}
.top-bar a {
    color: @color-white;
}
.top-bar .rtl a {
    direction: ltr;
}
 .navbar.affix .logo img {

    height: 54px;
    padding: 5px;
   
    margin-top: 2px;

}

section#content {
    background-color: @color-white;
}
