/*------------------------------------*\
    #CUSTOM
\*------------------------------------*/

.affix .lingue-mobile {
    display: none!important;
}

.navbar-toggle .icon-bar {

    background-color: #FFF;

}