
/*------------------------------------*\
    #ABOUT-SECTION
\*------------------------------------*/

/* Shortcode #1 */

.shotcode-1 h3 {
	color: @color-secondary;
	font-size: 18px;
	font-weight: 700;
font-family:@font-body;
	margin-bottom: 30px;
	line-height: 26px;
}

.shotcode-1 p {
	color: @color-gray3;
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
}

.shotcode-1 .btn {
	width: 170px;
}

.shotcode-1 .feature .feature-icon {
	color: @color-dark7;
	font-size: 40px;
	margin-bottom: 0
}

.shotcode-1 .feature h4 {
	color: @color-dark7;
	font-size: 16px;
	font-weight: 700;
	line-height: 25px;
	text-transform: uppercase;
}

.shotcode-1 .feature p {
	color: @color-gray3;
	font-size: 14px;
	font-weight:  400;
	line-height: 23px;
}

/* Shortcode #2 */
.shortcode-2 .feature,
.shortcode-6 .feature {
background-color:@color-white;
	box-shadow: 0 2px 3px rgba(40, 40, 40, 0.12);
	-moz-box-shadow: 0 2px 3px rgba(40, 40, 40, 0.12);
	-webkit-box-shadow: 0 2px 3px rgba(40, 40, 40, 0.12);
	padding: 16px;
	margin-bottom:0;
}

.shortcode-2 .feature .feature-icon,
.shortcode-6 .feature .feature-icon {
	margin-bottom: 10px
}

.shortcode-2 .feature h4,
.shortcode-6 .feature h4 {
	color: @color-dark7;
	font-size: 15px;
	font-weight: 700;
	line-height: 25px;
	text-transform: uppercase;
	margin-bottom: 0
}

.shortcode-2 p {
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
}

/* Shortcode #3 */
.shortcode-3 .feature p {
	font-size: 14px;
	font-weight:  400;
	line-height: 23px;
	margin-bottom: 0
}

.shortcode-3 h4 {
	color: @color-dark7;
	font-size: 16px;
	font-weight: 700;
	line-height: 25px;
	text-transform: uppercase;
	margin-bottom: 16px;
}

/* Shortcode #4 */

/* Shortcode #5 */
.shortcode-5 .feature {
	margin-bottom: 50px;
}

.shortcode-5 .feature.last {
	margin-bottom: 0
}

.shortcode-5 .feature h4 {
	font-size: 16px;
	font-weight: 700;
	line-height: 1;
	text-transform: uppercase;
}

.shortcode-5 .feature p {
	font-size: 14px;
	font-weight:  400;
	line-height: 23px;
	margin-bottom: 0;
}

.shortcode-5 .feature img {
	height: 415px;
}

.shortcode-5 .accordion .panel .panel-body {
	font-size: 14px;
	font-weight:  400;
	line-height: 25px;
}

/* Shortcode #6 */
.shortcode-6 p {
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	margin-bottom:0
}

.shortcode-6 .feature-2 {
	margin-bottom: 0
}

/* Shortcode #7 */
.shortcode-7 {
	border-top: 1px solid @color-gray9;
	border-bottom: 1px solid @color-gray9
}

.shortcode-7 .col-img {
	height: 510px;
	padding-top: 100px
}

.shortcode-7 .col-img .col-divider {
	display: block;
	width: 385px;
	height: 308px;
	opacity: 0.5;
border: 4px solid @color-white;
}

.shortcode-7 .col-heading {
	height: 510px;
	overflow: hidden;
	padding: 90px 60px;
}

.shortcode-7 .col-heading h2 {
	font-size: 26px;
	font-weight: 700;
	line-height: 34px;
	text-transform: uppercase;
}

.shortcode-7 .col-progress {
	height: 510px;
	padding: 120px 110px 120px 70px;
}

.shortcode-7 .progressbar {
	margin-bottom: 30px;
}

.shortcode-7 .progress {
	height: 4px;
	background-color: @color-gray9;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
}

.shortcode-7 .progress-bar {
background-color:@color-theme;
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
}

.shortcode-7 .progress-title {
	display: block;
	position: relative;
	width: 100%;
	margin-bottom: 8px;
}

.shortcode-7 .progress-title .title {
	color: @color-dark7;
	font-size: 13px;
	font-weight: 700;
	text-transform: uppercase;
}

.shortcode-7 .progress-title .value {
	float: right;
	color: @color-gray3;
	font-size: 13px;
	font-weight: 400;
	text-transform: uppercase;
}

/* Shortcode #8 */
.shortcode-8 .feature {
	margin-bottom: 50px
}

.shortcode-8 .feature-img {
	position: relative;
	height: 488px;
}

.shortcode-8 .feature-img img {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}

.shortcode-8 .feature-icon {
	width: 70px;
	height: 70px;
	line-height: 70px;
	display: block;
	-moz-border-radius: 1px;
	-webkit-border-radius: 1px;
	border-radius: 1px;
	background-color: @color-dark7;
	text-align: center;
	margin-bottom: 30px;
 color:@color-white;
	font-size: 40px;
	line-height: 70px;
}

.shortcode-8 .feature-icon i {
	line-height: 70px;
}

.shortcode-8 .feature-icon.right {
	float: right
}

.shortcode-8 .feature-icon.right + h4 {
	padding-top: 100px;
}

.shortcode-8 .feature h4 {
	color: @color-dark7;
	font-size: 16px;
	font-weight: 700;
	line-height: 25px;
	margin-bottom: 6px;
}

.shortcode-8 .feature p {
	font-size: 14px;
	font-weight: 400;
	line-height: 23px;
	margin-bottom: 0
}

/* Shortcode #9 */
.shortcode-9 .brand img {
	opacity: 0.7;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.shortcode-9 .brand img:hover {
	opacity: 1
}

.shortcode-2.about-home {
	overflow: visible;
	padding-top: 100px;
	padding-bottom: 100px;
}

.shortcode-2.about-home p {
	color: @color-gray3;
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
}

.shortcode-2.about-home .feature {
background-color:@color-gray
}

.shortcode-2.about-home .cta-form {
	margin-top: -211px;
	z-index: 999;
	box-shadow: 0 2px 3px rgba(40, 40, 40, 0.12);
	-moz-box-shadow: 0 2px 3px rgba(40, 40, 40, 0.12);
	-webkit-box-shadow: 0 2px 3px rgba(40, 40, 40, 0.12);
	position: absolute;
	width: 100%;
}

.shortcode-2.about-home .cta-form .cta.cta-2 {
	padding: 30px;

}

.shortcode-2.about-home .cta-icon {
	float: left;
	margin-right: 20px;
	padding-top: 6px;
}

.shortcode-2.about-home .cta-devider {
	padding-left: 60px;
	margin-left: 0
}

.shortcode-2.about-home .cta-form .form {
background-color:@color-gray;
	padding: 50px;
}

.shortcode-2.about-home .cta-form .form form {
	margin-bottom: 0
}

.shortcode-2.about-home .cta-form .form .form-control {
	margin-bottom: 20px;
}

.shortcode-2.about-home .cta-form .form textarea {
	margin-bottom: 30px !important;
}

.shortcode-2.about-home .cta-form p {
	color: @color-gray;
	font-size: 14px;
	font-weight: 400;
	line-height: 1;
	margin-bottom: 15px;
}

.shortcode-2.about-home .cta-form h5 {
color: @color-white;
	font-size: 22px;
	font-weight: 700;
	text-transform: uppercase;
	line-height: 1
}

.feature-bordered {
	box-shadow: none !important;
	-moz-box-shadow: none !important;
	-webkit-box-shadow: none !important;
	border: 1px solid rgba(49, 49, 49, 0.1);
}

/* Custom, iPhone Retina */ 
@media only screen and (min-width : 320px) and (max-width : 479px) {

.shotcode-1 .feature .feature-icon {
	margin-bottom: 0
}

.shortcode-2 .feature {
	padding-left: 0
}
}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 320px)and (max-width : 767px ) {

.shortcode-3 .pr-50 {
	padding-right: 0
}

.shortcode-3 .col-content {
	padding: 15px;
}

.shortcode-3 .col-content .feature {
	text-align: center
}

.shortcode-4 .facts-box {
	margin-bottom: 30px;
}

.shortcode-5 .feature {
	text-align: center
}

.shortcode-6 .feature {
	padding-left: 0;
	margin-bottom: 15px;
}

.shortcode-8 {
	text-align: center
}

.shortcode-8 .feature-icon {
	margin-right: auto;
	margin-left: auto;
	text-align: center
}

.shortcode-8 .feature-icon.right {
	float: none;
}

.shortcode-8 .feature-icon.right + h4 {
	padding-top: 0;
	margin-right: auto;
	margin-left: auto;
	text-align: center
}

.shortcode-8 .feature p {
	text-align: center
}

.shortcode-9 .brand {
	margin-bottom: 30px;
}

.shortcode-9 .brand:after {
	width: 0;
	height: 0;
	opacity: 0
}

.shortcode-7 .col-heading {
	height: auto
}

.shortcode-7 .col-img .col-divider {
	width: 100%
}

.shortcode-7 .col-progress {
	padding: 80px 15px;
	height: auto
}

.shortcode-2.about-home .cta-form {
	margin-top: 0;
	z-index: 1;
	position:relative;
}

.shortcode-2.about-home .cta-icon,
.shortcode-2.about-home .cta .cta-devider:before {
	display: none
}

.shortcode-2.about-home .cta.cta-2 .cta-desc,
 .shortcode-2.about-home .cta-devider {
	padding-left: 0
}

.shortcode-2.about-home .cta-form .form {
	padding: 30px 15px;
}

.about-home-2 .heading {
	margin-top: 0
}

.about-home-2 .cta-form {
	margin-top: 0;
	float: none;
	width: auto;
	height: auto;
	text-align: center !important;
	margin-bottom: 20px;
}

.about-home-2 .cta-form .cta,
.about-home-2 .cta-form .cta-model {
	padding-top: 20px;
	padding-bottom: 20px;
}

.about-home-2 .cta-form .cta-icon {
	display: none
}

.about-home-2 .cta-form h5 {
	margin-bottom: 0 !important
}

.about-home-2 .cta-form p {
	text-align: center !important
}
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) and (max-width : 991px) {
	.shortcode-2.about-home .cta-form {
		margin-top: 0;
		z-index: 1;
		position:relative;
	}
.shortcode-9 .brand {
	margin-bottom: 30px;
}

.shortcode-8 img {
	width: 90%;
	margin-right: auto;
	margin-left: auto;
	text-align: center
}

.shortcode-6 .feature-2 {
	padding-left: 0;
}

.shortcode-2.about-home .feature {
	padding-left: 0
}

.shortcode-2.about-home .cta-icon,
.shortcode-2.about-home .cta .cta-devider:before {
	display: none
}

.shortcode-2.about-home .cta-form {
	margin-top: 50px;
}

.shortcode-2.about-home .cta.cta-2 .cta-desc,
 .shortcode-2.about-home .cta-devider {
	padding-left: 0
}

.shortcode-2.about-home .cta-form .form {
	padding: 30px 15px;
}

.about-home-2 .cta-form {
	width: 270px;
	height: auto;
	margin-top: -207px;
}

.about-home-2 .cta-form .cta,
.about-home-2 .cta-form .cta-model {
	padding-top: 20px;
	padding-bottom: 20px;
}

.about-home-2 .cta-form .cta-icon {
	display: none
}

.about-home-2 .cta-form .cta-desc h5 {
	font-size: 20px;
}

.shortcode-7 .col-heading { height:auto}
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 991px) and (max-width : 1200px) {

.col-content {
	padding: 90px 0 50px 50px;
}

.shortcode-7 .col-img .col-divider {
	width: 100%
}

.shortcode-7 .col-progress {
	padding-right: 50px
}

.shortcode-7 .col-heading {
	padding: 80px 50px;
}

.shortcode-7 .col-heading h2 {
	font-size: 20px;
}

.shortcode-8 .feature-img img {
	bottom: -50px
}

.shortcode-2.about-home .cta-form {
	margin-top: -207px;
}

.shortcode-2.about-home .cta-form h5 {
	font-size: 17px;
}

.about-home-2 .cta-form {
	margin-top: -224px;
}
}
