/*------------------------------------*\
    #VARIABLES
\*------------------------------------*/

/* Colors Base */

@color-theme: 				#186594;
@color-body:				#585858;
@color-heading:				#1c405b;
@color-secondary:			#727272;



/* Baseline */

@baseline: 						 20px;



/* Font Base */

@line-height-base:				 1.5;
@line-height-content:			 1.8;
@font-body:						 'Encode Sans Condensed', sans-serif;
@font-heading:					 'Encode Sans Condensed', sans-serif;
@font-secondary:				 'Encode Sans Condensed', sans-serif;
@decoration-base:  				 none;



/* Font Size Base */

@font-size-base:				14px;
@font-size-h1:                 	32px;
@font-size-h2:                  28px;
@font-size-h3:                  25px;
@font-size-h4:                  22px;
@font-size-h5:                  18px;
@font-size-h6:                  @font-size-base;



/* Background Base */

@background-base:				  no-repeat center center fixed;
@background-size-base:			  cover;



/* Icon Base */

@awesome-font:					fontawesome;

/* Border-Radius Base */
@radius-5: 						5px;
@radius-6:						6px;
@radius-10:    					10px;
@radius-50: 					50%;


/* Media Queries Base */

@retina:       					~"(max-width: 767px)";
@iphone:       					~"only screen and (min-width : 320px)and (max-width : 479px)";
@extraSmall:   					~"only screen and (min-width : 480px)and (max-width : 767px) ";
@small:        					 ~"only screen and (min-width : 480px) and (max-width : 767px)";
@tablet:       					~"only screen and (min-width : 768px) and (max-width : 991px)";



/* Variation Colors Base */


@color-white:				#ffffff;
@color-black:				#000000;
@color-dark: 				#222222;
@color-gray:				#f9f9f9;
@color-gray2:				#9b9b9b;
@color-gray3:				#7c7c7c;
@color-gray4:				#5e5e5e;
@color-gray5:				#ececec;
@color-gray6:				#a9a9a9;
@color-gray7:				#eaeaea;
@color-gray8:				#e9e9e9;
@color-gray9:				#e5e5e5;
@color-gray10:				#f4f4f4;
@color-gray11:				#f6f6f6;
@color-dark2:				#1f1f1f;
@color-dark3:				#161616;
@color-dark4:				#1c1c1c;
@color-dark5:				#292929;
@color-dark6:				#313131;
@color-dark7:				#434343;
@color-dark8:				#efefef;
@color-dark9:				#9b1915;
@color-dark10:				#808080;
@color-dark11:				#828282;
@color-dark12:				#c5c5c5;
@color-dark13:				#e9e8e8;
@color-dark14:				#888888;




