
/*------------------------------------*\
    #ALIGN
\*------------------------------------*/
p,
pre,
ul,
ol,
dl,
dd,
blockquote,
address,
table,
fieldset,
form {
 margin-bottom: @baseline;
}

section {
	padding-top: 40px;
	padding-bottom: 40px;
	;
	overflow: hidden;
}

footer {
 padding-top:(@baseline*2);
 padding-bottom:(@baseline*2);
}

/* All margin */
.m-0 {
	margin: 0;
}

.m-xs {
margin:(@baseline/2);
}

.m-sm {
 margin:@baseline;
}

.m-md {
margin:@baseline*2;
}

.m-lg {
margin:@baseline*4;
}

/* top margin */
.mt-0 {
	margin-top: 0;
}

.mt-xs {
margin-top:(@baseline/2);
}

.mt-sm {
 margin-top:@baseline;
}

.mt-md {
margin-top:@baseline*2;
}

.mt-lg {
margin-top:@baseline*4;
}

.mt-30 {
	margin-top: 30px !important;
}

.mt-50 {
	margin-top: 50px !important;
}

.mt-60 {
	margin-top: 60px;
}

.mt-150 {
	margin-top: 150px;
}

/* bottom margin */
.mb-0 {
	margin-bottom: 0 !important;
}

.mb-xs {
margin-bottom:(@baseline/2);
}

.mb-sm {
 margin-bottom:@baseline;
}

.mb-md {
margin-bottom:@baseline*2 !important;
}

.mb-lg {
margin-bottom:@baseline*4;
}

.mb-30 {
	margin-bottom: 30px !important;
}

.mb-50 {
	margin-bottom: 50px!important;
}

.mb-60 {
	margin-bottom: 60px!important;
}

.mb-150 {
	margin-bottom: 150px!important;
}

/* right margin */
.mr-0 {
	margin-right: 0;
}

.mr-xs {
margin-right:(@baseline/2);
}

.mr-sm {
 margin-right:@baseline;
}

.mr-md {
margin-right:@baseline*2;
}

.mr-lg {
margin-right:@baseline*4;
}

.mr-30 {
	margin-right: 30px !important;
}

.mr-50 {
	margin-right: 50px;
}

.mr-60 {
	margin-right: 60px;
}

.mr-150 {
	margin-right: 150px;
}

/* left margin */
.ml-0 {
	margin-left: 0;
}

.ml-xs {
margin-left:(@baseline/2);
}

.ml-sm {
 margin-left:@baseline;
}

.ml-md {
margin-left:@baseline*2;
}

.ml-lg {
margin-left:@baseline*4;
}

.ml-30 {
	margin-left: 30px !important;
}

.ml-50 {
	margin-left: 50px;
}

.ml-60 {
	margin-left: 60px;
}

.ml-150 {
	margin-left: 150px;
}

/* All padding */
.p-0 {
	padding: 0;
}

.p-xs {
padding:(@baseline/2);
}

.p-sm {
 padding:@baseline;
}

.p-md {
padding:@baseline*2;
}

.p-lg {
padding:@baseline*4;
}

/* top padding */
.pt-0 {
	padding-top: 0;
}

.pt-xs {
padding-top:(@baseline/2);
}

.pt-sm {
 padding-top:@baseline;
}

.pt-md {
padding-top:@baseline*2;
}

.pt-lg {
padding-top:@baseline*4;
}

.pt-60 {
	padding-top: 60px;
}

/* bottom padding */
.pb-0 {
	padding-bottom: 0;
}

.pb-xs {
padding-bottom:(@baseline/2);
}

.pb-sm {
 padding-bottom:@baseline;
}

.pb-md {
padding-bottom:@baseline*2;
}

.pb-lg {
padding-bottom:@baseline*4;
}

.pb-50 {
	padding-bottom: 50px;
}

.pb-60 {
	padding-bottom: 60px;
}

.pb-100 {
	padding-bottom: 100px;
}

/* right padding */
.pr-0 {
	padding-right: 0;
}

.pr-xs {
padding-right:(@baseline/2);
}

.pr-sm {
 padding-right:@baseline;
}

.pr-md {
padding-right:@baseline*2;
}

.pr-lg {
padding-right:@baseline*4;
}

.pr-30 {
	padding-right: 30px !important;
}

.pr-50 {
	padding-right: 50px;
}

.pr-60 {
	padding-right: 60px;
}

.pr-150 {
	padding-right: 150px;
}

/* left padding */
.pl-0 {
	padding-left: 0;
}

.pl-xs {
padding-left:(@baseline/2);
}

.pl-sm {
 padding-left:@baseline;
}

.pl-md {
padding-left:@baseline*2;
}

.pl-lg {
padding-left:@baseline*4;
}

/* Postions */
.fixed {
	position: fixed;
}

.relative {
	position: relative;
}

.absolute {
	position: absolute;
}

.static {
	position: static;
}

/* Zindex*/
.zindex-1 {
	z-index: 1;
}

.zindex-2 {
	z-index: 2;
}

.zindex-3 {
	z-index: 3;
}

/* Borders */
.border-all {
border:        1px solid @color-theme;
}

.border-top {
border-top:    1px solid @color-theme;
}

.border-bottom {
border-bottom: 1px solid @color-theme;
}

.border-right {
border-right:  1px solid @color-theme;
}

.border-left {
border-left:   1px solid @color-theme;
}

/* Display */
.inline {
	display: inline;
}

.block {
	display: block;
}

.inline-block {
	display: inline-block;
}

.hide {
	display: none;
}

.flex {
	display: flex;
}

@media only screen and (max-width : 767px ) {

section {
	padding-top: 60px;
	padding-bottom: 60px;
}

.text-center-xs {
	text-align: center !important
}

.pull-none-xs {
	float: none !important;
	text-align: center !important
}

.mb-15-xs {
	margin-bottom: 15px;
}

.mb-30-xs {
	margin-bottom: 30px !important;
}

.mb-50-xs {
	margin-bottom: 50px;
}

.mb-60-xs {
	margin-bottom: 60px!important;
}
}

@media only screen and (min-width : 768px) and (max-width : 991px) {

.text-center-sm {
	text-align: center !important
}

.mb-0-sm {
	margin-bottom: 0;
}

.mb-15-sm {
	margin-bottom: 15px;
}

.mb-30-sm {
	margin-bottom: 30px !important;
}

.mb-50-sm {
	margin-bottom: 50px;
}

.mb-60-sm {
	margin-bottom: 60px;
}

.pb-15-sm {
	padding-bottom: 15px;
}

.pb-30-sm {
	padding-bottom: 30px;
}

.pb-50-sm {
	padding-bottom: 50px;
}

.pb-60-sm {
	padding-bottom: 60px;
}
}

.border-b {
	border-bottom: 1px solid @color-gray9;
}
