
/*------------------------------------*\
    #PRICING-SECTION
\*------------------------------------*/
.pricing .panel {
	text-align: center;
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
}

.pricing .panel-body {
 background-color:@color-theme;
	padding-top: 100px;
	padding-bottom: 100px;
}

.pricing .panel-body h4 {
 font-family:@font-body;
	font-weight: 700;
 color:@color-white;
	line-height: 46px;
	font-size: 100px;
	margin-bottom: 32px;
}

.pricing .panel-body .symbol {
	font-size: 30px;
}

.pricing .panel-body .time {
	font-size: 13px;
	color: @color-gray11;
}

.pricing .panel-body p {
	color: @color-dark6;
 font-family: @font-heading;
	font-size: 16px;
	font-weight: 700;
	line-height: 1;
	text-transform: uppercase;
	margin-bottom: 0;
}

.pricing .list-group {
	padding: 50px;
	background-color: @color-gray
}

.pricing .list-group li.list-group-item {
	border-top: none;
	border-left: none;
	border-right: none;
	background-color: transparent;
	padding: 0;
	border-bottom-color: @color-dark13;
	color: @color-gray3;
 font-family: @font-body;
	font-size: 13px;
	font-weight: 400;
	line-height: 40px;
}

.pricing .list-group li.list-group-item:last-child {
	border-bottom: none;
	padding-bottom: 0
}

.pricing .list-group li.list-group-item:first-child {
	padding-top: 0
}

.pricing .panel-footer {
	background-color: @color-gray;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
	padding: 0 50px 50px 50px;
}

.pricing .panel-footer .btn {
	margin-bottom: 0;
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) and (max-width : 991px) {

.pricing .panel-body {
	padding-top: 50px;
	padding-bottom: 50px;
	line-height: 30px;
}

.pricing .panel-body h4 {
	font-size: 70px;
}

.pricing .list-group {
	padding: 25px;
}

.pricing .panel-footer {
	padding: 0 25px 25px 25px;
}

.pricing .panel-footer .btn {
	font-size: 11px;
	padding: 0 16px;
}

.pricing .panel-footer .btn i {
	margin-left: 5px
}
}
