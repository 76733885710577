
/*------------------------------------*\
    #FOOTER-SECTION
\*------------------------------------*/
footer {
 background-color:@color-dark2;
	padding-top: 0;
	padding-bottom: 0;
}

footer hr {
	border-top-color: rgba(47, 47, 47, 0.2);
	margin-top: 0;
	margin-bottom: 0
}

.widgets-contact {
 background-color:@color-dark3;
	padding-top: 40px;
	padding-bottom: 40px;
}

.widgets-contact p {
	font-size: 14px;
	font-weight: 400;
	line-height: 28px;
	margin-bottom: 0;
}

.widgets-contact .widget-contact-icon {
	margin-right: 20px;
	margin-left: 20px;
}

.widgets-contact .widget-contact-icon i {
	font-size: 40px;
 color:@color-gray
}

.widgets-contact .widget-contact-icon:after {
	content: "";
	width: 2px;
	height: 22px;
	margin-left: 20px;
 background-color:@color-theme;
	display: inline-block
}

.widgets-contact .widget-contact-info p:last-child {
	color: @color-dark11;
 font-family: @font-heading;
	font-size: 16px;
	font-weight: 400;
	line-height: 23px;
}

.widgets-contact .widget {
	padding-left: 40px;
	padding-right: 10px;

 border-left:2px solid @color-dark2;
}

.widgets-contact .widget:first-child {
	border-left: none
}

.widgets-contact.bg-theme {
 background-color:@color-theme;
}

.widgets-contact.bg-theme .widget-contact-icon:after {
 background-color:@color-white;
}

.widgets-contact.bg-theme .widget-contact-icon i {
 color:@color-white
}

.widgets-contact.bg-theme .widget-contact-info p:last-child {
 color:@color-dark2;
}

.widgets-links {
	padding-top: 80px;
	padding-bottom: 20px;
}

.widgets-links h5 {
	font-size: 16px;
	font-weight: 400;
	line-height: 30px;
	margin-bottom: 10px;
}

.widgets-links p {
	color: @color-gray3;
	font-size: 14px;
	font-weight: 400;
	line-height: 23px;
}

.widgets-links .widget-about {
	padding-left: 0
}

.widgets-links .widget-about .widget-about-logo {
	padding-top: 47px
}

.widgets-links .widget-about .widget-about-info {
	padding-left: 76px;
}

.widgets-links ul li {
	margin-bottom: 0;
}

.widgets-links ul li a {
	color: #7f7f7f !important;
	font-size: 15px;
	font-weight: 400;
	line-height: 26px;
}

.widgets-links ul li a:hover {
color:@color-theme !important
}

.widget-social {
background-color:@color-dark4;
	padding-top: 34px;
	padding-bottom: 34px;
	padding-left: 0
}

.widget-social p {
	font-size: 14px;
	font-weight: 400;
	line-height: 23px;
	color: @color-gray;
}

.copyrights {
	padding-top: 40px;
	padding-bottom: 40px;
}

.widget-social-icon a {
	margin-left: 8px;
	width: 42px;
	height: 42px;
	line-height: 42px;
	-moz-border-radius: 1px;
	-webkit-border-radius: 1px;
	border-radius: 1px;
	background-color: @color-dark3;
	font-size: 15px;
	font-weight: 400;
	color: @color-gray3;
	display: block;
	float: left;
	text-align: center;
	margin-top: 2px;
	overflow: hidden
}

.widget-social-icon a i {
	display: block;
	position: relative;
	line-height: 42px;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.widget-social-icon a:hover {
 background-color:@color-theme;
 color:@color-dark
}

.widget-social-icon a:hover i:first-child {
	margin-top: -42px;
}

.widget-social-icon a i:last-child {
color:@color-dark
}

.widget-newsletter-form form {
	margin-bottom: 0;
	height: 42px;
	line-height: 42px;
}

.widget-newsletter-form form .input-group {
	width: 390px;
}

.widget-newsletter-form form .form-control {
	background-color: transparent;
 color:@color-gray3;
 border:2px solid @color-dark5;
	border-radius: 0;
	font-size: 12px;
	font-style: italic;
	width: 320px;
	height: 42px;
	line-height: 42px;
	padding-left: 16px;
	font-weight: 400;
}

.widget-newsletter-form form .form-control:focus {
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
}

.widget-newsletter-form form .btn {
 background-color:@color-dark5;
 font-family:@font-heading;
	font-size: 13px;
 color:@color-white;
	text-transform: uppercase;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
	padding: 0 20px;
	height: 42px;
	line-height: 42px;
	text-align: center
}

.widget-newsletter-form form .btn:hover,
.widget-newsletter-form form .btn:focus {
	border-color: transparent;
color:@color-theme
}

.copyrights p {
	color: @color-gray2;
	line-height: 24px;
	font-weight: 400;
	margin-bottom: 0;
	font-size: 13px;
}

.copyrights a {
 color:@color-theme
}

.copyrights a:hover,
.copyrights a:focus,
.copyrights a:active {
 color:@color-white !important
}

/* footer #5 */
.footer-5 .widget-social {
	background-color: transparent;
	border-bottom: 1px solid rgba(47, 47, 47, 0.2);
}

/* Custom, iPhone Retina */ 
@media only screen and (min-width : 320px) and (max-width : 767px) {

.widgets-links {
	padding-top: 30px;
	padding-bottom: 30px;
}

.widgets-contact .widget-contact-info p:last-child {
	font-size: 12px;
}

.widgets-contact .widget {
	margin-bottom: 24px;
}

.widgets-contact .widget:last-of-type {
	margin-bottom: 0
}

.widgets-links .widget-about .widget-about-logo {
	padding-right: 0;
	padding-top: 0;
	margin-bottom: 12px;
}

.widgets-links .widget-about .widget-about-info {
	padding: 0
}

.widget-social-icon a {
	margin-bottom: 15px;
	text-align: center
}

.widget-newsletter-form form .input-group {
	width: 100%;
}

.widget-newsletter-form form .form-control {
	width: 100%
}

.widgets-links ul li {
	margin-bottom: 10px;
}

.widget-social-icon {
	margin: auto;
	margin-left: auto;
}

.widget-social-icon a {
	display: inline-block;
	float: none
}
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) and ( max-width:1200px) {

.widget-social-icon,
.widget-social-info,
.widget-newsletter-info,
.widget-newsletter-form {
	float: none !important
}

.widget-social-info,
.widget-newsletter-info {
	width: 100%;
	margin-bottom: 10px;
}

.widget-social-icon a:first-of-type {
	margin-left: 0
}
}
