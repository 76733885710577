
/*------------------------------------*\
    #ACTION-SECTION
\*------------------------------------*/
.cta-desc p {
	margin-bottom: 0;
	font-size: 14px;
	color: @color-gray;
	font-weight: 400;
}

.cta-desc h5 {
	margin-bottom: 0;
	font-size: 22px;
	font-weight: 700;
	text-transform: uppercase;
 color:@color-white;
	line-height: 1
}

.cta-devider {
	margin-left: 55px;
}

.cta-devider:before {
	content: "";
	width: 2px;
	height: 23px;
	background-color: transparent;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform:translateY(-50%);
	-moz-transform:translateY(-50%);
	-o-transform:translateY(-50%);
	-ms-transform:translateY(-50%);
}

/* Call To action #1 */
.cta-1,
.cta-2 {
	padding: 40px 35px;
	position: relative
}

.cta-1 .cta-img {
	position: relative
}



.cta-1 .cta-img img {
    position: absolute;
    top: -84px;
    max-height: 200px;
}

.cta-1 .cta-desc {
	padding-left: 20px;
}
.cta-1 .cta-desc p,
.cta-2 .cta-desc p{
	margin-bottom:5px;
}

.cta-1 .cta-action {
	float: right
}

/* Call To action #2 */
.cta-2 i,
.cta-3,
.cta-4 i {
	font-size: 40px;
 color:@color-white;
	text-align: center;
	line-height: 1;
}

.cta-2 .cta-desc {
	padding-left: 80px;
}

.cta-2 .cta-icon {
	position: absolute
}

/* Call To action #3 */
.cta-3,.cta-4   { padding-top:100px; padding-bottom:100px;}
.cta-3 h2,
.cta-4 h2 {
	font-size: 55px;
	font-weight: 700;
	line-height: 50px;
 color:@color-white;
	text-transform: uppercase;
	margin-bottom: 40px;
}

.cta-3 p,
.cta-4 p {
	color: @color-gray;
 font-family: @font-secondary;
	font-size: 17px;
	font-weight: 700;
	font-style: italic;
	line-height:26px;
	margin-bottom: 26px;
}

.cta-3 p:first-child,
.cta-4 p:first-child { line-height:1}



/* Call To action #4 */
.cta-4 h2 {
	padding-top:30px;
	padding-bottom:30px;
	margin-bottom:0
}

.cta-p {
 color: @color-heading !important;
	font-size: 17px;
	font-weight: 600;
	line-height: 45px;
	margin-bottom: 30px;
}

/* Call To action #5 */
.cta-5 {
	padding: 40px 0px;
}

.cta-5 .cta-2 { padding:0}

/* Call To action #6 */
.cta-6 {
	padding-top: 134px;
	padding-bottom: 134px;
	overflow: inherit
}

.cta-6 h2 {
 color: @color-white;
 font-family: @font-heading;
	font-size: 39px;
	font-weight: 700;
	line-height: 42px;
	text-transform: uppercase;
	margin-bottom: 50px;
}

.cta-6 p {
	color: @color-gray;
	font-size: 17px;
	font-weight: 600;
	line-height: 45px;
}

.cta-6 .cta-img {
	position: absolute;
	right: 0;
	top: 70px;
}

/* Module POPUP */
.modal-open .navbar-fixed-top,
.modal-open .navbar-fixed-bottom {
	padding-right: 17px;
}

.modal-dialog {
	margin-top: 40px;
	width: 360px;
}

.modal-content {
	-moz-border-radius:0;
	-webkit-border-radius:0;
	border-radius:0;
	background-color: @color-gray;
	box-shadow: 0 2px 3px rgba(40, 40, 40, 0.12);
	-moz-box-shadow:0 2px 3px rgba(40, 40, 40, 0.12);
	-webkit-box-shadow:0 2px 3px rgba(40, 40, 40, 0.12);
	border: none
}

.modal-body {
	padding: 30px 50px 50px;
}

.modal-body form {
	margin-bottom: 0
}

.modal-body .form-control {
	margin-bottom: 20px;
}

.modal-body .btn {
	text-align: center;
	margin-right: auto;
	margin-left: auto
}

.modal-header .close {
	position: absolute;
	font-size: 24px;
	top: -24px;
	right: 0;
 color: @color-white;
 text-shadow: 0 1px 0 @color-white;
	opacity: 1
}

.modal-header {
	border-bottom: none;
 background-color:@color-theme;
	padding: 33px;
}

.modal-header .model-icon {
	position: absolute
}

.modal-header .model-title {
	text-align: left;
	padding-left: 80px;
	position: relative;
}

.modal-header .model-divider {
	position: relative;
}

.modal-header .model-divider:before {
	content: "";
	width: 2px;
	height: 23px;
	background-color: @color-dark9;
	position: absolute;
	top: 50%;
	left: 55px;
	transform: translateY(-50%);
	-webkit-transform:translateY(-50%);
	-moz-transform:translateY(-50%);
	-o-transform:translateY(-50%);
	-ms-transform:translateY(-50%);
}

.modal-header .model-title p {
 color: @color-white;
	font-size: 14px;
	font-weight: 400;
}

.modal-header .model-title h6 {
 color: @color-white;
	font-size: 22px;
	font-weight: 700;
	line-height: 1px;
	text-transform: uppercase;
}

/* Custom, iPhone Retina */ 
@media only screen and (min-width : 320px) and (max-width : 767px ) {
	
.cta {
	text-align: center
}
.cta-devider { margin-left:0}
.cta-devider:before { display:none}
.cta-1 .cta-desc,.cta-2 .cta-desc { padding-left:0}
.cta-1 .cta-action { float:none !important}
.cta-desc p { margin-bottom:10px;}
.cta-desc h5 { margin-bottom:20px;}
.cta-2 .cta-icon { position:relative;}
.cta-2 .btn-primary { margin-right:0; margin-bottom:10px;}
.cta-2 .btn-secondary { margin-bottom:10px;}
.cta-6 {
	padding-top: 80px;
	padding-bottom: 80px;
}

.cta-6 h2 {
	font-size: 30px;
}

.modal-dialog {
	margin-right: auto;
	margin-left: auto
}
.modal-dialog {
	width: 100%;
	margin-left: auto;
	margin-right: auto
}

.modal-header .model-title h6 {
	font-size: 17px;
}
}


/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px)and (max-width : 767px ) {
	.cta-2 .btn-primary { margin-right:10px; margin-bottom:0;}
	.cta-2 .btn-secondary { margin-bottom:0;}
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) and (max-width : 991px) {
	.cta-1{ padding-top:20px; padding-bottom:20px;}
	.cta-1 .cta-devider  { margin-left:137px;}
	.cta-1 .cta-action { float:none; margin-left:137px; margin-top:10px;}
	.cta-1 .cta-img img { top:-57px;}
	.cta-2 { text-align:center}
	.cta-2 .cta-icon { position:relative; margin-right:auto; margin-left:auto}
	.cta-2 .cta-devider:before { display:none}
	.cta-2 .cta-desc { padding-left:0; margin-bottom:20px;}
	.cta-2 .cta-action { float:none !important; text-align:center; margin-left:auto; margin-right:auto; margin-top:10px;}
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) and (max-width:1200px) {
	.cta-desc h5 { font-size:18px;}
	.cta-2 .btn { width:150px;}
	.cta-6 .cta-img { top:125px;}
}
