
/*------------------------------------*\
    #HERO-SECTION
\*------------------------------------*/
.hero {
	padding-top: 0;
	padding-bottom: 0;
	margin: 0;
	overflow: hidden;
	position: relative;
}

.hero:before {
	content: "";
	top: 0;
	left: 0;
	width: 100%;
	display: inline-block;
	position: absolute;
	background-color: rgba(34, 34, 34, 0.3);
	background-image: linear-gradient(180deg, rgba(25, 23, 23, 0.64) 0%, transparent 49%, transparent 82%, transparent 100%);
}

.hero .slides {
	padding-right: 0;
	padding-left: 0;
	overflow: hidden
}

.hero .slides li {
	height: 100%
}

.hero h6 {
font-family:@font-body;
	font-size: 17px;
	text-transform: capitalize;
	color: #e5e4e4;
	font-weight: 600;
	line-height: 45px;
}

.hero h2 {
	font-size: 55px;
	font-weight: 700;
	line-height: 50px;
	text-transform: uppercase;
color:@color-theme;
	margin-bottom: 30px;
}

.hero p {
	font-size: 17px;
	font-weight: 700;
	line-height: 26px;
color:@color-white;
}

.rev_slider:after {
	width: 100%;
	height: 100%;
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	z-index: 10;
	pointer-events: none;
	background: rgba(25, 23, 23, 0.8); /* fallback for old browsers */
	background: -webkit-linear-gradient(to bottom, rgba(25, 23, 23, 0.8), rgba(34, 34, 34, 0.2)); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to bottom, rgba(25, 23, 23, 0.8), rgba(34, 34, 34, 0.2)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.rev_slider .arrow {
	width: 27px !important;
	height: 71px;
	line-height: 71px;
	opacity: 1;
background-color:@color-heading !important
}

.rev_slider .arrow:before {
	line-height: 71px;
}

.rev_slider .arrow {
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}

.rev_slider .arrow:hover {
	width: 71px!important;
background-color:@color-theme!important;
	z-index: 9999;
}

/* home 2 */
.hero-2 {
	padding-top: 0;
	padding-bottom: 0;
	position: relative
}

.hero-slider .item {
	height: 650px;
	position: relative
}

.hero-slider .item .item-bg {
	position: absolute;
	background-attachment: inherit
}

.hero-slider .item .item-bg:before {
	background-color: rgba(34, 34, 34, 0.15);
}

.hero-slider .item .container {
	z-index: 4;
	position: relative
}

.hero-slide {
	position: absolute;
	width: 370px;
	height: 235px;
 background-color: @color-white;
	margin-top: 211px;
	padding: 30px;
}

.hero-slide .slide-heading {
	border-bottom: 1px solid rgba(49, 49, 49, 0.1);
	margin-bottom: 20px;
	text-transform: capitalize
}

.hero-slide .slide-heading p {
	color: @color-secondary;
	font-size: 14px;
	font-weight: 400;
	line-height: 1;
	text-transform: capitalize;
	margin-bottom: 17px;
}

.hero-slide .slide-title h2 {
color: @color-theme;
	font-size: 20px;
	font-weight: 700;
	line-height: 29px;
	text-transform: uppercase;
}

.hero-slide .slide-action a {
	width: 145px;
}

.hero-slider .owl-controls {
	position: absolute;
	left: 100px;
	bottom: 35px;
}

.hero-slider .owl-controls .owl-nav div {
	width: 27px;
	height: 71px;
	line-height: 71px;
	background-color: @color-heading !important;
	border-radius: 0 !important;
	opacity: 1;
	text-align: center;
	-webkit-transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-o-transition: all 0.3s linear;
	transition: all 0.3s linear;
}

.hero-slider .owl-controls .owl-nav div:hover {
background-color:@color-theme !important;
	width: 71px;
}

.hero-slider .owl-controls .owl-nav div i {
color: @color-white;
	font-family: FontAwesome;
	font-size: 20px;
	font-weight: 400;
	line-height: 28px;
}

/* Hero #3 */
.hero-3 .cta-form {
	width: 370px;
	height: 106px;
	float: right;
	margin-top: -128px;
}

/* Hero #4 */
.hero-4 .widget-bottom {
	position: absolute;
	width: 100%;
	height: 141px;
	margin-top: -141px;
	border-top: 1px solid rgba(255, 255, 255, 0.1);
	z-index: 900
}

.hero-4 .widgets-contact {
	background-color: transparent !important;
	padding-top: 0;
	padding-bottom: 0
}

.hero-4 .widgets-contact .widget {
	border-left: 1px solid rgba(255, 255, 255, 0.1);
	height: 141px;
	padding-top: 45px;
}

.hero-4 .widgets-contact .widget:first-child {
	border-left: 1px solid rgba(255, 255, 255, 0.1);
}

/* Hero #5 */
.about-home-2 {
	overflow: visible;
}

.about-home-2 .cta-form {
	width: 370px;
	height: 280px;
	float: right;
	margin-top: -214px;
}

.about-home-2 .cta-form .cta {
	border-bottom: 2px solid @color-white;
}

.about-home-2 .cta-form .cta-model {
	padding: 50px;
	text-align: center;
}

.about-home-2 .btn-block {
	width: 100% !important;
}

/* Hero #7 */
.header-7 + .hero {
	padding-top: 0
}

.header-7 .top-bar {
	border-bottom: none
}

/* Slider Bullet */
.slider-bullet {
	width: 12px !important;
	height: 12px !important;
}

.slider-bullet .tp-bullet {
	width: 12px !important;
	height: 12px !important;
	background-color: transparent !important
}

.slider-bullet .tp-bullet span {
	width: 8px;
	height: 8px;
	opacity: 1;
	display: block;
	text-align: center;
	background-color: rgba(255, 255, 255, 0.7) !important;
	margin: 2px;
}

.slider-bullet .tp-bullet.selected span,
.slider-bullet .tp-bullet:hover span {
background-color:@color-theme !important;
}

.slider-bullet .tp-bullet.selected,
.slider-bullet .tp-bullet:hover {
outline:2px solid @color-theme !important
}

/* Custom, iPhone Retina */ 
@media only screen and (min-width : 320px) and (max-width : 479px) {

.rev_slider .arrow {
	display: none
}

.rev_slider .btn {
	width: 100px;
	height: 30px;
	line-height: 30px;
	font-size: 12px;
}

.hero-slider .item {
	height: 500px;
	text-align: center
}

.hero-slide {
	width: 300px;
	height: auto;
	margin-top: 100px;
	padding: 30px 15px;
}

.hero-slide .slide-action a {
	width: 110px;
}

.slide-action .pull-right {
	float: none !important
}

.hero-3 .cta-form {
	margin-top: 0;
	width: 100%;
	height: auto
}

.hero-3 .cta-form .cta {
	padding-top: 20px;
	padding-bottom: 20px;
}

.hero-3 .cta-form .cta .cta-desc p {
	text-align: center !important
}

.hero-3 .cta-form .cta .cta-desc h5 {
	margin-bottom: 0
}
}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px)and (max-width : 767px ) {

.rev_slider .arrow {
	display: none
}

.rev_slider .btn {
	width: 100px;
	height: 40px;
	line-height: 40px;
	font-size: 12px;
}

.hero-slider .item {
	height: 550px;
	text-align: center
}

.hero-slide {
	margin-top: 100px;
}

.hero-3 .cta-form {
	margin-top: 0;
	width: 100%;
	height: auto
}

.hero-3 .cta-form .cta {
	padding-top: 20px;
	padding-bottom: 20px;
}

.hero-3 .cta-form .cta .cta-desc p {
	text-align: center !important
}

.hero-3 .cta-form .cta .cta-desc h5 {
	margin-bottom: 0
}
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) and (max-width : 991px) {

.rev_slider .arrow {
	height: 51px
}

.rev_slider .arrow:hover {
	height: 51px!important;
}

.rev_slider .arrow:before {
	line-height: 51px;
}

.hero-4 .arrow {
	display: none
}

.hero-4 .widgets-contact .widget {
	padding-left: 20px;
}

.hero-4 .widgets-contact .widget-contact-icon {
	margin-left: 0
}

.hero-4 .widget-contact-info {
	padding-left: 86px;
}

.hero-4 .widgets-contact p {
	font-size: 12px;
}

.hero-4 .widgets-contact p:last-child {
	font-size: 13px !important;
}

.hero-3 .cta-form {
	margin-top: 0;
	width: 300px;
	height: auto;
	margin-top: -116px;
}

.hero-3 .cta-form .cta {
	padding-top: 20px;
	padding-bottom: 20px;
}

.hero-3 .cta-form .cta i {
	font-size: 30px;
}

.hero-3 .cta-form .cta .cta-desc {
	margin-bottom: 0
}

.hero-3 .cta-form .cta .cta-desc p {
	text-align: center !important
}

.hero-3 .cta-form .cta .cta-desc h5 {
	margin-bottom: 0;
	font-size: 17px;
}
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 991px) and (max-width : 1200px) {

.hero-4 .widgets-contact .widget {
	padding-left: 20px;
}

.hero-4 .widgets-contact .widget-contact-icon {
	margin-left: 0
}

.hero-4 .widget-contact-info {
	padding-left: 86px;
}

.hero-4 .widgets-contact p {
	font-size: 13px;
}

.hero-4 .widgets-contact p:last-child {
	font-size: 14px !important;
}

.hero-3 .cta-form {
	margin-top: -124px;
}
}
