/*------------------------------------*\
    #LOADING-SECTION
\*------------------------------------*/
.preloader {
    width:100%;
    height:100%;
    left:0;
    top:0;
    position:fixed;
    z-index:99999;
    background-color:@color-white;
	overflow:hidden;
}


.spinner {
  margin: -40px 0 0 -40px;
  width: 70px;
  text-align: center;
  position:absolute;
  top:50%;
  left:50%;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: @color-theme;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}