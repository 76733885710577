
/*------------------------------------*\
    #HEADER
\*------------------------------------*/
.transparent-header {
	background-color: transparent;
	height: 94px;
}

.transparent-header + section {
	margin-top: -94px; /*padding-top:210px;*/
}

.transparent-header .navbar {
	background-color: transparent;
	height: 94px;
	border-bottom: 1px solid rgba(255,255,255,0.1);
}

.navbar {
	margin-bottom: 0
}

.navbar.affix {
	background-color: rgba(0,0,0,0.7);
	padding-top: 0
}

.navbar .logo {
	height: 94px;
	line-height: 94px;
}

.navbar-nav {
	margin-right: 0
}

.navbar-nav >li {
	margin-right: 30px;
	height: 94px;
}

.navbar-nav li a {
	font-size: 13px;
 font-family:@font-heading;
	text-transform: uppercase;
	font-weight: 700;
}

.navbar-nav >li >a {
 color:@color-white;
	line-height: 94px;
	padding: 0;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
	background-color: transparent;
	border: none;
}

.navbar-nav > li.active {
 border-bottom:2px solid @color-theme;
}

.navbar-nav > li.active > ul {
	margin-top: 0;
}

.navbar-nav > li > a:hover,
.navbar-nav > li > a:focus {
	background-color: transparent
}

/* Menu Level #1 */
.navbar-nav > li:after {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	height: 2px;
 background: @color-theme;
	content: '';
	opacity: 0;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
	transform: translateY(-10px);
	-webkit-transform: translateY(-10px);
	-moz-transform: translateY(-10px);
	-o-transform: translateY(-10px);
	-ms-transform: translateY(-10px);
}

.navbar-nav >li:hover::after {
	height: 2px;
	opacity: 1;
	transform: translateY(0);
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-o-transform: translateY(0);
	-ms-transform: translateY(0);
	margin-top: -2px
}

.navbar-nav > li.active:hover::after {
	opacity: 0;
}

.module {
	display: inline-block;
	position: relative
}

.module span.title {
	display: none
}

.module-search .search-icon {
	line-height: 94px;
	cursor: pointer;
}

.module-search .search-icon i {
	font-size: 14px;
 color:@color-white;
 border-left:1px solid @color-white;
	padding-left: 30px;
	padding-right: 20px;
	line-height: 23px;
}

.module-search .search-icon:hover i {
 color:@color-theme;
}

.module-search .search-box .search-form {
	margin-bottom: 0
}

.module-search .search-box .search-form .input-group {
	height: 100%;
}

.module-search .search-box .search-form .btn {
 background-color:@color-theme;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
 color:@color-white
}

.module-search .search-box .search-form input {
	border: none;
	font-size: 13px;
	text-transform: capitalize;
	margin-bottom: 0;
 color:@color-gray3;
	height: 100%;
	padding-right: 0;
	padding-top: 11px;
	padding-bottom: 11px;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
}

.module-cart .cart-icon {
	line-height: 94px;
	font-size: 14px;
 color:@color-white;
	cursor: pointer;
}

.module-cart .cart-label {
 background-color:@color-theme;
	font-size: 11px;
	font-weight: bold;
	padding: 2px 3px
}

.cart-box .cart-overview li {
 border-bottom:1px solid @color-gray7;
	margin-bottom: 20px;
	position: relative
}

.cart-box .cart-overview li:last-child {
	margin-bottom: 0
}

.cart-box .cart-overview li a {
	font-size: 0
}

.cart-box .cart-overview li a:after {
 font-family:@awesome-font;
	content: "\f00d";
	font-size: 12px;
	position: absolute;
	text-align: center;
	right: 0;
	top: 0;
	width: 19px;
	height: 19px;
	line-height: 19px;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
 background-color:@color-black;
 color:@color-white
}

.cart-box .cart-overview img {
	width: 70px;
	height: 70px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	margin-right: 15px;
	position: absolute
}

.cart-box .cart-overview .product-meta {
	padding-left: 90px;
}

.cart-box .cart-overview h5 {
	font-size: 13px;
	text-transform: uppercase;
 color:@color-dark6;
	margin-bottom: 0;
	line-height: 23px;
}

.cart-box .cart-overview p {
	font-size: 12px;
	line-height: 22px;
 color:@color-gray6;
	margin-bottom: 0;
}

.cart-total {
	margin-top: 30px;
	margin-bottom: 30px;
}

.cart-total .total-desc {
	float: left;
	text-align: left
}

.cart-total .total-desc h5,
.cart-total .total-price h5 {
	font-size: 14px;
	font-weight: bold;
	text-transform: uppercase;
 color:@color-gray4
}

.cart-total .total-price {
	float: right;
	text-align: right;
}

.cart-box .cart-control .btn {
	width: 150px;
}

/* Header #2 */
.full-header {
	height: 143px;
background-color: @color-white;
	box-shadow: 0 2px 3px rgba(39, 41, 48, 0.03);
	-moz-box-shadow: 0 2px 3px rgba(39, 41, 48, 0.03);
	-webkit-box-shadow: 0 2px 3px rgba(39, 41, 48, 0.03);
}

.top-bar {
	height: 49px;
	line-height: 49px;
	border-bottom: 1px solid rgba(49, 49, 49, 0.1);
}

.top-bar .list-inline > li {
	padding-left: 0;
	padding-right: 0
}

.top-bar ul,
.top-bar p {
	margin-bottom: 0
}

.top-bar .top-contact p {
	font-size: 13px;
	font-weight: 400;
	line-height: 49px;
	color: @color-gray;
	margin-right: 15px;
}

.top-bar .top-contact span {
	color: @color-gray2;
}

.top-bar ul.top-contact li {
	border-right: 1px solid rgba(49, 49, 49, 0.1);
}

.top-bar ul.top-contact li:first-child {
	padding-right: 15px;
	padding-left: 0
}

.top-bar ul.top-contact li:last-child {
	border-right: none;
	padding-left: 15px;
}

.top-bar ul.top-widget li {
	border-left: 1px solid rgba(49, 49, 49, 0.1)
}

.top-bar ul.top-widget .top-social {
	padding-right: 5px;
	padding-left: 0;
	text-align: center
}

.top-bar ul.top-widget .top-social a span {
	border: 1px solid rgb(80, 80, 80);

}
.top-bar ul.top-widget .top-social a.active span {
	border: 1px solid #FFFFFF;

}
.top-bar ul.top-widget .top-social a i {
	border-right: 1px solid rgba(49, 49, 49, 0.1);
	color: @color-gray3;
	font-size: 14px;
	font-weight: 400;
	line-height: 28px;
	padding-left: 18px;
	padding-right: 18px;
}

.top-bar ul.top-widget .top-social a:hover i {
color:@color-theme
}

.top-bar ul.top-widget .top-social a:last-child i {
	border-right: none
}

.top-bar .button-quote {
	background-color: @color-theme;
color: @color-white;
font-family: @font-body;
	font-size: 13px;
	font-weight: 400;
	text-transform: capitalize;
	padding: 10px 9px;
	margin-left: 10px;
}

.top-bar .button-quote:hover {
background-color:@color-heading;
}

.full-header .navbar-fixed-top {
	top: 49px;
	height: 94px;
}

.full-header .navbar-nav > li > a {
	color: @color-dark6;
}

.full-header .module-search .search-icon i,
.full-header .module-cart .cart-icon {
	color: @color-dark6;
}

.full-header .module-search .search-icon i {
	border-left-color: rgba(49, 49, 49, 0.1);
}

.full-header .navbar.affix {
background-color:@color-white
}

.full-header .navbar-fixed-top.affix {
	top: 0
}

/* Header #3 */
.header-3 {
	background-color: transparent;
	height: 118px;
}

.header-3 + section {
	margin-top: -118px; /*padding-top:210px;*/
}

.header-3 .navbar-fixed-top {
	top: 0;
	height: 118px;
}

.header-3 .top-bar {
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	margin-bottom: 25px;
}

.header-3 .top-bar ul.top-widget li {
	border-left: none
}

.header-3 .top-bar ul.top-widget .top-social a:first-child i {
	border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.header-3 .top-bar ul.top-widget .top-social a i {
	border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.header-3 .top-bar ul.top-contact li {
	border-right: none
}

.header-3 .top-bar .top-contact p {
	padding-right: 15px;
	padding-left: 15px;
	border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.header-3 .navbar-nav > li {
	height: 43px;
}

.header-3 .navbar .logo {
	line-height: 43px;
	height: 43px;
}

.header-3 .navbar-nav > li > a,
.header-3 .module-search .search-icon,
.header-3 .module-cart .cart-icon {
	line-height: 43px;
 color:@color-white
}

.header-3 .module-search .search-icon i {
color:@color-white
}

.header-3 .navbar-fixed-top.affix .top-bar {
	display: none
}

.header-3 .navbar-fixed-top.affix {
	background-color: rgba(0, 0, 0, 0.7);
}

.header-3 .navbar-fixed-top.affix,
.header-3 .navbar-fixed-top.affix .navbar-nav > li,
.header-3 .navbar-fixed-top.affix .navbar-header {
	height: 70px;
}

.header-3 .navbar-fixed-top.affix .navbar-header,
.header-3 .navbar-fixed-top.affix .navbar-nav > li > a,
.header-3 .navbar-fixed-top.affix .module-cart .cart-icon,
.header-3 .navbar-fixed-top.affix .module-search .search-icon {
	line-height: 70px;
}

/* header #3-2*/
.header-3.style-2 + section {
	margin-top: -70px;
}

.header-3.style-2 {
	height: 70px;
}

.header-3.style-2 .navbar-fixed-top {
	padding-top: 25px;
	height: 70px;
}

.header-3.style-2 .navbar-fixed-top.affix {
	padding-top: 0
}

/* Header #5 */
.header-5 {
	height: 113px;
}

.header-5 .navbar-nav > li > a,
.header-5 .module-search .search-icon,
.header-5 .module-cart .cart-icon {
	line-height: 73px;
}

.header-5 .navbar-fixed-top {
	height: 73px;
	top: 40px;
}

.header-5 .navbar-nav > li {
	height: 73px;
}

.header-5 .navbar .logo {
	height: 43px;
	line-height: 1;
	top: 0px;
	position: absolute;
}

.header-5 .affix .logo {
	position: relative;
	line-height: 73px;
}

.header-5 .top-bar {
	border-bottom: none;
	height: 39px;
	line-height: 39px;
}

.header-5 .top-bar ul.top-contact li {
	border-right: none
}

.header-5 .top-bar ul {
	border-bottom: 1px solid rgba(49, 49, 49, 0.1);
	width: 95%;
	float: right;
}

.top-bar ul.top-contact li:first-child {
	padding-right: 0
}

.header-5 .top-bar ul li p {
	line-height: 20px;
	margin-right: 0
}

.header-5 .top-bar ul li:first-child p {
	padding-right: 15px;
	border-right: 1px solid rgba(49, 49, 49, 0.1);
}

.header-5 .module-search .search-icon i {
	border-left: none;
	width: 35px;
	height: 29px;
	line-height: 29px;
	text-align: center;
	border: 1px solid rgba(49, 49, 49, 0.1);
	padding-left: 0;
	padding-right: 0
}

.header-5 .module-cart .cart-icon i {
	width: 35px;
	height: 29px;
	line-height: 29px;
	text-align: center;
	border: 1px solid rgba(49, 49, 49, 0.1);
}

/* Header #6 */
.header-5.style-2 + section {
	margin-top: -73px;
}

.header-5.style-2 {
	height: 73px;
}

.header-5.style-2 .navbar-fixed-top {
	padding-top: 0;
	height: 73px;
	top: 0
}

.header-5.style-2 .navbar-fixed-top.affix {
	padding-top: 0
}

.header-5.style-2 .navbar .logo {
	top: auto
}

.header-5.style-2 .navbar-nav > li > a,
.header-5.style-2 .module-search .search-icon i,
.header-5.style-2 .module-cart .cart-icon {
color:@color-white;
}

.header-5.style-2 .module-search .search-icon i,
.header-5.style-2 .module-cart .cart-icon i {
border:2px solid @color-white
}

.header-5.style-2 .top-bar .top-contact p {
	color: @color-gray;
}

.header-5.style-2 .top-bar .top-contact span {
	color: @color-dark12;
}

.header-5.style-2 .affix .top-bar {
	display: none
}

.header-5.style-2 .navbar.affix {
	background-color: rgba(0, 0, 0, 0.7);
}

/* Header #7 */
.header-7 {
	background-color: transparent
}

.header-7 .navbar-fixed-top {
	top: 0;
}

.header-7 + section {
	margin-top: -143px;
	padding-top: 143px;
}

.header-7 .outer {
background-color:@color-white
}

.header-7 .top-bar .top-bar-inner {
	height: 50px;
	border-bottom: 1px solid rgba(49, 49, 49, 0.1);
}

.header-7 .top-bar {
	margin-bottom: 0
}

.header-7 .top-bar .list-inline {
	margin-left: 0
}

.header-7 .affix .top-bar {
	display: none
}

.header-7 .affix .outer {
	background-color: transparent
}

.header-7 .navbar-nav > li,
.header-7 .navbar.affix {
	height: 93px;
}

.header-7 .navbar .logo {
	height: 94px;
	line-height: 94px;
}

.header-7 .module-search .search-icon {
	line-height: 94px;
}

.header-7 .container.outer {
	height: 94px;
}
.header-7.style-4.dark .navbar.affix { background-color:transparent;}

/* header #8 */
.header-8 {
	background-color: transparent;
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
}

.header-8 .navbar-fixed-top {
	top: 0;
}

.header-8 + section {
	margin-top: 23px;
}

.header-8 .outer {
background-color:@color-white
}

.header-8 .outer-2 {
background-color:@color-theme
}

.header-8 .top-bar {
	height: 139px;
	border-bottom: none
}

.header-8 .top-bar .top-bar-inner {
	height: 139px;
}

.header-8 .navbar-nav > li,
.header-8 .affix {
	height: 59px;
}

.header-8 .affix {
background-color:@color-theme !important
}

.header-8 .navbar-nav > li > a,
.header-8 .module-search .search-icon,
.header-8 .module-cart .cart-icon {
	line-height: 57px;
}

.header-8 .navbar-nav > li > a {
color:@color-white
}

.header-8 .affix .top-bar {
	display: none
}

.header-8 .navbar-nav > li:after {
	background-color: @color-dark6;
}

.header-8 .navbar-nav > li.active {
	border-bottom-color: @color-dark6;
}

.header-8 .module-cart .cart-box,
.header-8 .module-search .search-box {
	border-top-color: @color-dark6;
	margin-top: -1px;
}

.header-8 .navbar .logo {
	height: 94px;
	line-height: 139px;
}

.header-8 .module-search .search-icon i {
	border-left: none;
	padding-right: 20px;
	padding-left: 0;
}

.header-8 .module-cart .cart-label {
	background-color: @color-dark6;
color:@color-theme
}

.header-8 .top-bar-contact {
	margin-top: 30px;
}

.header-8 .top-bar-contact ul {
	margin-right: -15px;
}

.header-8 .top-bar-contact li.widget {
	width: 280px;
	height: 79px;
	border: 1px solid rgba(49, 49, 49, 0.08);
	line-height: 79px;
}

.header-8 .top-bar-contact li:first-child {
	margin-right: 30px
}

.header-8 .top-bar-contact li {
	padding-left: 20px;
}

.header-8 .top-bar-contact li i {
	color: @color-dark11;
	font-size: 40px;
	line-height: 70px;
}

.header-8 .top-bar-contact .widget-contact-icon:after {
	content: "";
	width: 2px;
	height: 22px;
	margin-left: 20px;
 background-color:@color-theme;
	display: inline-block
}

.header-8 .widget-contact-info {
	margin-top: 16px;
	padding-left: 85px;
}

.header-8 .widget-contact-info p:first-of-type {
	color: @color-dark11;
	font-family: Raleway;
	font-size: 14px;
	font-weight: 400;
}

.header-8 .widget-contact-info p:last-of-type {
	color: @color-secondary;
font-family: @font-heading;
	font-size: 16px;
	font-weight: 400;
	line-height: 23px;
}

/* Header #9 */
.header-9 .module-quote {
	line-height: 94px;
}

.header-9 .button-quote {
 background-color: @color-theme;
	color: @color-white;
	font-family: 'Raleway', sans-serif;
	font-size: 13px;
	font-weight: 400;
	line-height: 20px;
	text-transform: capitalize;
	padding: 10px 9px;
	margin-left: 20px;
}

.header-9 .module-quote:before {
	content: "";
	position: absolute;
	width: 1px;
	height: 23px;
	background-color: rgba(255, 255, 255, 0.1);
	left: 0;
	display: inline-block;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
}

.header-9 .navbar-nav > li:last-child {
	margin-right: 20px;
}

/* Header #10 */
.header-10 .navbar {
	background-color: rgba(255, 255, 255, 0.05);
}

.header-10 .navbar.affix {
	background-color: rgba(0, 0, 0, 0.7);
}

/* Header #11 */
.header-7.style-3 {
	height: 94px;
}

.header-7.style-3 +section {
	margin-top: -94px;
	padding-top: 94px
}

.header-7.style-3 .navbar-nav > li,
.header-7.style-3 .navbar.affix {
	height: 94px;
}

/* Header #12 */
.header-7.style-4 {
	height: 124px;
}

.header-7.style-4 +section {
	margin-top: -124px;
	padding-top: 124px
}

.header-7.style-4 .navbar-nav > li,
.header-7.style-4 .navbar.affix {
	height: 94px;
}

.header-7.style-4 .container.outer {
	margin-top: 30px
}

.header-7.style-4 .affix .container.outer {
	margin-top: 0
}

.header-7.style-4.dark .container.outer {
	background-color: @color-dark6;
}

.header-7.style-4.dark .navbar-nav > li > a,
.header-7.style-4.dark .module-search .search-icon i,
.header-7.style-4.dark .module-cart .cart-icon {
color:@color-white
}

.header-7.style-4.dark .module-search .search-icon i {
	border-left-color: rgba(255, 255, 255, 0.1);
}

/* model */
.modal-open .modal {
	background-color: rgba(34, 34, 34, 0.95);
}

.modal-header .model-icon {
	font-size: 40px;
	line-height: 41px;
color:@color-white
}

.model-title p {
	color: @color-gray !important;
	font-size: 14px !important;
	font-weight: 500 !important;
	line-height: 30px !important;
	margin-bottom: 0;
	padding-left: 0 !important
}

.model-title h6 {
color: @color-white;
	font-size: 22px;
	font-weight: 700;
	line-height: 1 !important;
	text-transform: uppercase;
	margin-bottom: 0
}

.modal-header span {
	font-size: 24px !important;
	font-weight: 400 !important;
	line-height: 28px;
color:@color-white !important
}

/* Custom, iPhone Retina */ 
@media only screen and (min-width : 320px) and (max-width : 767px) {

.navbar-nav {
	margin: 0;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.navbar-toggle {
 border-color:@color-theme;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
	margin-top: 35px;
}

.navbar-toggle .icon-bar {
 background-color:@color-theme
}

.container > .navbar-header {
	margin-left: 0
}

.container > .navbar-collapse {
	width: 100%;
 background-color:@color-heading;
	margin-right: 0
}

.navbar-nav > li {
	height: auto !important;
	margin-right: 0
}

.navbar-nav > li.pull-left {
	float: none !important
}

.navbar-nav > li > a {
	line-height: 36px;
 color:@color-white;
	padding-right: 0;
	padding-left: 0
}

.navbar-nav > li:after {
	display: none
}

.navbar-nav > li.active {
	border-bottom: none
}

.navbar-nav > li.has-dropdown > ul.dropdown-menu,
ul.mega-menu {
	background-color: transparent;
	position: relative;
	padding-left: 0;
}

.navbar-nav > li.has-dropdown > a:after,
li.dropdown-submenu > a:after {
 font-family:@awesome-font;
	content: "\f105";
	font-size: 13px;
	position: absolute;
	right: 0;
}

.navbar-nav > li.has-dropdown.open > a,
.navbar-nav > li.has-dropdown.open > a:hover,
.navbar-nav > li.has-dropdown.open > a:focus {
	background-color: transparent;
	border: none;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1)
}

li.open a,
li.open a:hover,
li.open a:focus {
color:@color-white;
	line-height: 21px;
}

li.dropdown-submenu.open > ul > li > a {
	padding-left: 50px !important;
}

.navbar-nav > li.has-dropdown.open > a:after,
.navbar-nav > li.has-dropdown.open > a:hover:after,
.navbar-nav > li.has-dropdown.open > a:focus:after,
li.dropdown-submenu.open > a:after,
li.dropdown-submenu.open > a:hover:after,
li.dropdown-submenu.open > a:focus:after {
 font-family:@awesome-font;
	content: "\f107";
	font-size: 13px;
	position: absolute;
	right: 0px;
}

li.dropdown-submenu > a:after,
li.dropdown-submenu.open > a:hover:after,
li.dropdown-submenu.open > a:focus:after {
	right: 10px;
}

.nav > li > a:hover,
.nav > li > a:focus,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.mega-menu li a:hover,
.mega-menu li a:focus {
	background-color: transparent
}

.dropdown-menu > li > a,
.mega-menu > li > a {
 color:@color-white
}

.mega-menu ul {
	list-style: none;
	padding-left: 25px;
}

.mega-menu ul li {
	padding-top: 5px
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.mega-menu li a:hover,
.mega-menu li a:focus {
 color:@color-white
}

ul.mega-menu {
	list-style: none;
	display: none;
}

.has-dropdown.open > .mega-menu,
.dropdown-submenu.open > .mega-menu {
	display: block
}

.dropdown-submenu.open > .mega-menu ul {
	padding-left: 50px;
}

.module {
	display: block;
	width: 100%;
	border-bottom: 1px solid rgba(255,255,255,0.1);
}

.module span.title {
	display: inline-block;
 color:@color-white;
	font-size: 13px;
	font-family: 'Montserrat', sans-serif;
	text-transform: uppercase;
}

.module .search-box,
.module .cart-box {
	display: none
}

.module-search .search-icon,
.module-cart .cart-icon {
	line-height: 36px !important;
}

.module-search .search-icon i {
	border-left: none;
	padding-left: 0;
	padding-right: 5px;
}

.module.toggle-module .search-box,
.module.toggle-module .cart-box {
	display: block !important
}

.module-search.toggle-module .search-box .search-form {
	margin-bottom: 10px;
}

.module-search.toggle-module .search-box .search-form input {
	background-color: @color-white;
	margin-bottom: 0;
	height: 35px;
	line-height: 35px;
	padding-left: 10px
}

.module-cart.toggle-module .cart-box {
	background-color: @color-white;
	padding: 30px 20px;
	margin-bottom: 10px;
}

.module-cart.toggle-module .cart-box .cart-control {
	margin-right: auto;
	margin-left: auto;
	text-align: center
}

.module-cart.toggle-module .cart-box .cart-control .pull-right {
	float: none !important
}

/* Header #2 */
.full-header .top-bar .top-contact p {
	font-size: 11px;
}

.full-header .text-right {
	text-align: center !important
}

.top-bar ul.top-widget .top-social:first-child {
	border-left: none
}

.top-bar ul.top-widget .top-social a i {
	padding-right: 10px;
	padding-left: 10px
}

.full-header .navbar-nav > li > a,
.full-header .module-search .search-icon i,
.full-header .module-cart .cart-icon {
color:@color-white
}

/* Header #3 */
.header-3 .navbar-fixed-top.affix .navbar-nav > li > a,
.header-3 .navbar-fixed-top.affix .module-cart .cart-icon,
.header-3 .navbar-fixed-top.affix .module-search .search-icon {
	line-height: 43px;
}

.header-3 .navbar-fixed-top.affix,
.header-3 .navbar-fixed-top.affix .navbar-header {
	height: 94px;
}

.header-3 .navbar .logo,
.header-3 .navbar-fixed-top.affix .navbar-header {
	line-height: 94px;
}

.header-3 .navbar-fixed-top.affix .navbar-nav > li {
	height: auto !important
}

.header-3.style-2 .navbar-fixed-top {
	padding-top: 0;
	height: 70px;
}

.header-3.style-2 .affix .navbar-header {
	height: 70px !important;
	line-height: 70px;
}

.header-3.style-2 .affix,
.header-3.style-2 .logo,
.header-3.style-2 .navbar-header {
	line-height: 70px;
}

.header-3.style-2 .navbar-toggle {
	margin-top: 20px !important
}

/* Header #5 */
.header-5 .top-bar ul {
	float: none;
	width: auto
}

.header-5 .navbar .logo {
	line-height: 60px
}

.header-5 .navbar-toggle {
	margin-top: 20px;
}

.header-5 .navbar-nav > li > a {
	line-height: 36px;
}

/* Header #8 */
.header-8 {
	height: 94px
}

.header-8 .outer-2 {
	background-color: transparent
}

.header-8 .navbar-nav > li > a {
	line-height: 39px
}

.header-8 .affix {
	height: auto
}

.header-8 .container > .navbar-collapse {
	margin-left: 0
}

.header-8 .module-right {
	float: none !important
}

.header-8 .top-bar {
	height: 49px;
	line-height: 49px
}

.header-8 .top-bar .top-bar-inner {
	height: auto
}

.header-8 .navbar .logo {
	line-height: 94px
}

.header-8 + section {
	margin-top: 0;
	padding-top: 0
}

.header-8 .affix {
	opacity: 0;
	visibility: hidden;
}

.header-8 .navbar-toggle {
	margin-right: 0
}

/* Header #9 */
.header-9 .module-quote:before {
	opacity: 0;
	visibility: hidden
}

.header-9 .button-quote {
	margin-left: 0;
}

.header-9 .navbar-nav > li:last-child {
	margin-right: 0
}

.header-9 .module-quote {
	line-height: 40px;
}

.header-7.style-3 .navbar.affix {
	height: auto
}

.header-7.style-4 .container.outer {
	margin-top: 0
}
}

@media only screen and (min-width : 320px) and (max-width : 479px) {

.module-cart.toggle-module .cart-box .cart-control .btn {
	margin-bottom: 5px
}

.header-5 .navbar .logo {
	position: relative
}
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) and (max-width : 991px) {

.navbar-header {
	float: none !important;
	margin-right: auto;
	margin-left: auto;
	text-align: center
}

.navbar-toggle {
 border-color:@color-theme;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
	margin-top: 35px;
	display: block;
	float: left;
}

.navbar-toggle .icon-bar {
 background-color:@color-theme
}

.navbar-fixed-top .navbar-collapse {
	overflow: scroll !important;
}

.navbar-collapse.collapse {
	display: none!important;
}

.collapse.in {
	display: block !important;
}

.navbar-nav {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}

.navbar-nav>li {
	float: none;
}

.container > .navbar-collapse {
	width: 100%;
 background-color:@color-heading;
	margin-right: 0
}

.navbar-nav > li {
	height: auto !important;
	margin-right: 0
}

.affix .navbar-nav > li {
	height: auto !important;
}

.navbar-nav > li.pull-left {
	float: none !important
}

.navbar-nav > li > a {
	line-height: 36px !important;
 color:@color-white !important;
	padding-right: 0;
	padding-left: 0
}

.navbar-nav > li:after {
	display: none
}

.navbar-nav > li.active {
	border-bottom: none
}

.navbar-nav .dropdown-menu {
	background-color: transparent;
	position: relative;
	width: 100%;
	border: none;
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
}

.navbar-nav > li.has-dropdown > a:after,
.navbar-nav > li.has-dropdown > a:after,
li.dropdown-submenu > a:after {
 font-family:@awesome-font;
	content: "\f105";
	font-size: 13px;
	position: absolute;
	right: 0;
}

.navbar-nav > li.has-dropdown.open > a,
.navbar-nav > li.has-dropdown.open > a:hover,
.navbar-nav > li.has-dropdown.open > a:focus {
	background-color: transparent;
	border: none;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1)
}

li.dropdown-submenu.open > a,
li a:hover,
li.open > a:focus,
.mega-menu > li > a {
color:@color-white
}

li.dropdown-submenu.open > ul > li > a {
	padding-left: 50px !important;
}

.navbar-nav > li.has-dropdown.open > a:after,
.navbar-nav > li.has-dropdown.open > a:hover:after,
.navbar-nav > li.has-dropdown.open > a:focus:after,
li.dropdown-submenu.open > a:after,
li.dropdown-submenu.open > a:hover:after,
li.dropdown-submenu.open > a:focus:after {
 font-family:@awesome-font;
	content: "\f107";
	font-size: 13px;
	position: absolute;
	right: 0px;
}

li.dropdown-submenu > a:after,
li.dropdown-submenu.open > a:hover:after,
li.dropdown-submenu.open > a:focus:after {
	right: 10px;
}

.nav > li > a:hover,
.nav > li > a:focus,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
	background-color: transparent
}

.dropdown-menu > li > a,
.mega-menu a {
color:@color-white
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
color:@color-white
}

.mega-menu ul {
	list-style: none;
	padding-left: 0;
}

.mega-menu ul li {
	padding-top: 5px
}

.mega-menu ul ul {
	padding-left: 0
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.mega-menu li a:hover,
.mega-menu li a:focus {
 color:@color-white
}

ul.mega-menu {
	list-style: none;
	display: none;
}

.has-dropdown.open > .mega-menu,
.dropdown-submenu.open > .mega-menu {
	display: block;
	padding-left: 25px;
}

.dropdown-submenu.open > .mega-menu ul {
	padding-left: 25px;
}

.module {
	display: block;
	width: 100%;
	border-bottom: 1px solid rgba(255,255,255,0.1);
	padding: 5px 15px
}

.module span.title {
	display: inline-block;
 color:@color-white;
	font-size: 13px;
	font-family: 'Montserrat', sans-serif;
	text-transform: uppercase;
}

.module .search-box,
.module .cart-box {
	display: none
}

.module-search .search-icon,
.module-cart .cart-icon {
	line-height: 36px !important;
}

.module-search .search-icon i {
	border-left: none;
	padding-left: 0;
	padding-right: 5px;
}

.module.toggle-module .search-box,
.module.toggle-module .cart-box {
	display: block !important
}

.module-search.toggle-module .search-box .search-form {
	margin-bottom: 10px;
}

.module-search.toggle-module .search-box .search-form input {
	background-color: @color-white;
	margin-bottom: 0;
	height: 35px;
	line-height: 35px;
	padding-left: 10px
}

.module-cart.toggle-module .cart-box {
	background-color: @color-white;
	padding: 30px 20px;
	margin-bottom: 10px;
}

.module-cart.toggle-module .cart-box .cart-control {
	margin-right: auto;
	margin-left: auto;
	text-align: center
}

.module-cart.toggle-module .cart-box .cart-control .pull-right {
	float: none !important
}

/* Header #2 */
.full-header .top-bar .top-contact p {
	font-size: 11px;
}

.top-bar ul.top-widget .top-social a i {
	padding-right: 10px;
	padding-left: 10px
}

.full-header .module-search .search-icon i,
.full-header .module-cart .cart-icon {
color:@color-white
}

/* Header #3 */
.header-3 .navbar-fixed-top {
	height: 118px;
}

.header-3 .navbar-toggle {
	margin-top: 0 !important
}

.header-3 .affix .navbar-toggle {
	margin-top: 35px !important;
}

.header-3 .navbar-fixed-top.affix .navbar-nav > li > a,
.header-3 .navbar-fixed-top.affix .module-cart .cart-icon,
.header-3 .navbar-fixed-top.affix .module-search .search-icon {
	line-height: 43px;
}

.header-3 .navbar-fixed-top.affix,
.header-3 .navbar-fixed-top.affix .navbar-header {
	height: 94px;
}
 .header-3 .navbar .logo, .header-3 .navbar-fixed-top.affix .navbar-header, {
line-height:94px;

float: right;
}

.header-3.style-2 .navbar-fixed-top {
	padding-top: 0;
	height: 70px;
}

.header-3.style-2 .affix .navbar-header {
	height: 70px !important;
	line-height: 70px;
}

.header-3.style-2 .affix,
.header-3.style-2 .logo,
.header-3.style-2 .navbar-header {
	line-height: 70px;
}

.header-3.style-2 .navbar-toggle {
	margin-top: 20px !important
}

/* Header #5 */
.header-5 .top-bar ul {
	float: right;
	width: 86%
}

.header-5 .navbar .logo {
	line-height: 60px;
	position: relative
}

.header-5 .navbar-toggle {
	margin-top: 20px;
}

/* Header #8 */
.header-8 {
	height: 94px
}

.header-8 .outer-2 {
	background-color: transparent
}

.header-8 .container > .navbar-collapse {
	margin-left: 0
}

.header-8 .module-right {
	float: none !important
}

.header-8 .top-bar {
	height: 49px;
	line-height: 49px
}

.header-8 .top-bar .top-bar-inner {
	height: auto
}

.header-8 .navbar .logo {
	line-height: 94px
}

.header-8 + section {
	margin-top: 0;
	padding-top: 0
}

.header-8 .affix {
	opacity: 0;
	visibility: hidden;
}

.header-8 .navbar-toggle {
	margin-right: 0;
	float: right;
}

.header-8 .navbar-header {
	text-align: left;
	margin-left: 0
}
}

@media only screen and (min-width : 992px) {

/* Menu with Dropdown */
.navbar-nav > li.has-dropdown > ul.dropdown-menu,
.navbar-nav > li.has-dropdown > ul.mega-menu,
li.dropdown-submenu > ul.mega-menu {
 background-color:@color-white;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
	transform: translateY(10%);
	-webkit-transform: translateY(10%);
	-moz-transform: translateY(10%);
	-o-transform: translateY(10%);
	-ms-transform: translateY(10%);
	opacity: 0;
	position: absolute;
	left: 0;
	right: auto;
	box-shadow: 0 2px 3px rgba(40, 40, 40, 0.12);
	-moz-box-shadow: 0 2px 3px rgba(40, 40, 40, 0.12);
	-webkit-box-shadow: 0 2px 3px rgba(40, 40, 40, 0.12);
	border: none;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
	display: block;
	visibility: hidden;
}

.navbar-nav > li.has-dropdown > ul.dropdown-menu {
	width: 220px;
	padding: 16px 30px
}

.navbar-nav > li.has-dropdown > ul.mega-menu,
li.dropdown-submenu > ul.mega-menu {
	width: 460px!important;
	padding: 16px 30px
}

li.has-dropdown:hover > ul.dropdown-menu,
li.has-dropdown:hover > ul.mega-menu,
li.dropdown-submenu:hover > ul.mega-menu {
	transform: translateY(0);
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-o-transform: translateY(0);
	-ms-transform: translateY(0);
	opacity: 1;
	display: block;
	visibility: visible;
}

ul.dropdown-menu > li,
ul.mega-menu > li {
	padding-right: 0;
	height: 39px;
	line-height: 39px;
	position: relative;
	vertical-align: top;
}

ul.mega-menu > li {
	width: 190px !important;
	height: auto;
	overflow: hidden;
	display: inline-block
}

ul.mega-menu > li:first-child {
	margin-right: 15px;
}

ul.mega-menu > li > ul {
	width: 190px;
	list-style: none;
 background-color:@color-white;
	padding-left: 0;
	position: relative !important;
	left: auto !important;
}

ul.mega-menu li ul li {
	height: 39px;
	line-height: 39px;
}

ul.dropdown-menu li a,
ul.mega-menu li ul li a {
	line-height: 39px;
 color:@color-gray4;
	padding: 0;
 border-bottom:1px solid @color-gray5;
	display: block;
	font-size: 11px;

}

ul.mega-menu li ul li span {
 color:@color-theme;
	padding: 0;
	display: block;
	font-size: 13px;
	line-height: 39px;
	text-transform: uppercase;
 font-family:@font-heading;
	font-weight: 700;
}

ul.dropdown-menu li:last-child > a {
	border-bottom: none
}

ul.dropdown-menu li > a:hover,
ul.dropdown-menu li > a:focus,
ul.mega-menu li a:hover,
ul.mega-menu li a:focus {
	background-color: transparent;
 color:@color-theme
}

ul.dropdown-menu.left {
	left: -220px !important;
	margin-left:0 !important
}

ul.mega-menu.left {
	left: -520px!important;
}

/* Menu Level #2 */
li.has-dropdown > ul.dropdown-menu > li.dropdown-submenu > a:after {
 font-family:@awesome-font;
	content: "\f105";
	font-size: 13px;
	position: absolute;
	right: 0;
}

li.has-dropdown > ul.dropdown-menu > li.dropdown-submenu > ul.dropdown-menu,
li.dropdown-submenu > ul.mega-menu {
 background-color:@color-white;
	padding: 16px 30px;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
	transform: translateX(10%);
	-webkit-transform: translateX(10%);
	-moz-transform: translateX(10%);
	-o-transform: translateX(10%);
	-ms-transform: translateX(10%);
	opacity: 0;
	display: block;
	position: absolute;
	left: 100%;
	right: auto;
	top: 0;
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	border: none;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
	margin-left: 30px;
	z-index: 1
}

li.has-dropdown > ul.dropdown-menu > li.dropdown-submenu:hover > ul.dropdown-menu {
	-webkit-transform: translateX(0px);
	-moz-transform: translateX(0px);
	transform: translateX(0px);
	opacity: 1;
	z-index: 3;
}

li.dropdown-submenu.pull-left > ul.dropdown-menu {
	left: auto;
	right: 0;
	margin-right: 0;
}

.module-search .search-box {
	position: absolute;
	display: block;
 background-color:@color-white;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
 border-top:2px solid @color-theme;
	right: 0;
	width: 270px;
	padding: 20px;
	box-shadow: 0 2px 3px rgba(40, 40, 40, 0.12);
	-moz-box-shadow: 0 2px 3px rgba(40, 40, 40, 0.12);
	-webkit-box-shadow: 0 2px 3px rgba(40, 40, 40, 0.12);
	z-index: 99;
	margin-top: -2px;
	transform: translateY(10%);
	-webkit-transform: translateY(10%);
	-moz-transform: translateY(10%);
	-o-transform: translateY(10%);
	-ms-transform: translateY(10%);
}

.module-search:hover .search-box {
	opacity: 1;
	visibility: visible;
	display: block;
	-webkit-transform: translateY(0px);
	-moz-transform: translateY(0px);
	transform: translateY(0px);
}

.module-cart .cart-box {
	position: absolute;
	display: block;
 background-color:@color-white;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
 border-top:2px solid @color-theme;
	right: 0;
	width: 370px;
	padding: 30px;
	box-shadow: 0 2px 3px rgba(40, 40, 40, 0.12);
	-moz-box-shadow: 0 2px 3px rgba(40, 40, 40, 0.12);
	-webkit-box-shadow: 0 2px 3px rgba(40, 40, 40, 0.12);
	z-index: 99;
	-webkit-transform: translateY(10%);
	-moz-transform: translateY(10%);
	transform: translateY(10%);
	margin-top: -4px;
}

.module-cart:hover .cart-box {
	opacity: 1;
	visibility: visible;
	display: block;
	-webkit-transform: translateY(0px);
	-moz-transform: translateY(0px);
	transform: translateY(0px);
}
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 992px) and (max-width : 1200px) {

.navbar-nav > li {
	margin-right: 16px
}

.module-search .search-icon i {
	padding-left: 16px;
	padding-right: 16px;
}

.header-5 .top-bar .top-contact p {
	font-size: 11px;
}

.header-5 .top-bar ul {
	float: none;
	width: auto
}
}
