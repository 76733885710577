
/*------------------------------------*\
    #RESPONSIVE-DESIGN
\*------------------------------------*/
/* Custom, iPhone Retina */ 
@media only screen and (min-width : 320px) and (max-width : 479px) {

.projects-fullwidth.home-3-custom .projects-filter ul {
	text-align: center !important
}

.entry-cat,
.entry-no-comments,
.entry-views {
	font-size: 12px !important
}

.entry-no-comments {
	margin-right: 8px;
}

.testimonial-3 .testimonial-content:before {
	font-size: 40px;
	padding-top: 2px;
}

.testimonial-3 .testimonial-content p {
	padding-top: 50px;
	font-size: 14px;
	line-height: 23px;
}

.rev_slider h1 {
	font-size: 17px !important
}

.entry-bio .entry-bio-desc a {
	margin-right: 20px;
}

.full-header .navbar-header {
	margin-left: 15px;
	margin-right: 0
}

.header-5 .top-bar ul li:first-child p {
	padding-right: 10px;
}
}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px)and (max-width : 767px ) {

.projects-fullwidth.home-3-custom .projects-filter ul {
	text-align: center !important
}

.testimonial-3 .testimonial-content:before {
	font-size: 60px;
	padding-top: 16px;
}

.testimonial-3 .testimonial-content p {
	padding-top: 70px;
	font-size: 17px;
	line-height: 29px;
}

.rev_slider h1 {
	font-size: 17px !important
}
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) and (max-width : 991px) {

.service-2 .nav-tabs li a {
	font-size: 13px;
}

.service-2 .col-content {
	padding: 40px 15px 40px 40px;
}

.cta-1 .cta-img img {
	top: -50px;
}

.widgets-contact .widget,
.widget-about {
	margin-bottom: 24px;
}

.widgets-links {
	padding-top: 40px;
	padding-bottom: 40px;
}

.member .member-img {
	text-align: center;
}

.header-5 .navbar-fixed-top {
	top: 51px
}
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {

.shortcode-6 p {
	margin-bottom: 20px;
	text-align: center
}
}
